<template>
  <SearchableWrapper
    :tableConfig="tableConfig"
    :isValid="isValid"
    :isEditingEnabled="isEditingEnabled"
    :loading="loading"
    @reload:requested="reloadData"
    :item="item"
    :link="true"
  >
    <template #dialog>
      <BonusEstimateDialog @update:validation="checkValidation($event)" :item="item" @update:config="updateConfig($event)" />
    </template>
    <template #values>
      <PageableRenderValues default-sort="year" :order-by="tableConfig.filter.direction" :applicable-filters="item.applicableFilters" ref="renderValues" :widget-id="item.props.id" :t-color="tableConfig.color" :initialized="item.initialized" :headers="headers"/>
    </template>
  </SearchableWrapper>
</template>

<script>
  import ChartWrapper from "@/module/dashboard/components/widgets/chart/common/ChartWrapper.vue";
  import {TableWidgetMixin} from "@/module/dashboard/mixins/table-widget.mixin";
  import {ReloadWidgetMixin} from "@/module/dashboard/mixins/reload-widget.mixin";
  import i18n from "@/plugins/i18n";
  import ShowFilteredData from "@/module/dashboard/components/common/ShowFilteredData.vue";
  import SearchableWrapper from "@/module/dashboard/components/widgets/table/common/complex/SearchableWrapper.vue";
  import PageableRenderValues from "@/module/dashboard/components/widgets/table/common/complex/PageableRenderValues.vue";
  import BonusEstimateDialog from "@/module/dashboard/components/widgets/table/estimate/BonusEstimateDialog.vue"

  export default {
    components: {
      BonusEstimateDialog,
      PageableRenderValues, SearchableWrapper, ShowFilteredData, ChartWrapper},
    mixins: [TableWidgetMixin, ReloadWidgetMixin],
    computed: {
      headers: () => [
        {
          text: i18n.t("widgets.table.ESTIMATE.headers.year"),
          value: "year",
          type: "text",
          sortable: false,
        },
        {
          text: i18n.t("widgets.table.ESTIMATE.headers.quantity"),
          value: "quantity",
          type: "number",
          sortable: false,
        }
      ],
    }
  }
</script>

<style scoped>
  .v-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    overflow: auto !important;

    .v-card__text {
      padding-bottom: 0;
    }
  }

  .data-table-card {
    border-radius: 8px;
    overflow: hidden;
  }

  .title-text {
    font-weight: bold;
    margin-left: 8px;
    color: #2c3e50; /* Dark text for contrast */
  }

  .search-input {
    max-width: 300px;
  }
</style>
