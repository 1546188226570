<template>
  <v-card class="data-table-card" flat style="height: 100%">
    <v-card-title class="d-flex align-center ma-2 mr-0">
      <v-icon left :color="tableConfig.color">{{ tableConfig.icon }}</v-icon>
      <span class="title-text">{{ tableConfig.title }}</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        rounded
        outlined
        dense
        class="search-input mx-2"
      ></v-text-field>
      <EntityDialog :item="item" @update:config="updateConfig($event)" />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="tableConfig.filter.headers"
        :items="tableConfig.values"
        :items-per-page="5"
        :search="search"
        class="elevation-0"
        height="100%"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import EntityDialog from "@dashboard/components/widgets/table/entity/EntityDialog.vue";

export default {
  components: {EntityDialog},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: '',
      tableConfig: {
        title: this.item.props.title,
        icon: this.item.props.icon,
        color: this.item.props.color,
        values: this.item.props.values,
        filter: this.item.props.filter
      }
    };
  },
  methods: {
    updateConfig(newConfig) {
      this.tableConfig = { ...this.tableConfig, ...newConfig };
      // Aquí aplicas la configuración actualizada al componente de tarjeta
      this.tableConfig.id = this.id;
      this.tableConfig.title = newConfig.title;
      this.tableConfig.icon = newConfig.icon;
      this.tableConfig.color = newConfig.color;
      this.tableConfig.filter = {
        entity: newConfig.filter.entity,
        headers: newConfig.filter.headers
      };

      this.$emit('update:config', this.tableConfig);
    }
  }
}
</script>

<style scoped>
.v-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  overflow: auto !important;

  .v-card__text {
    padding-bottom: 0;
  }
}

.data-table-card {
  border-radius: 8px;
  overflow: hidden;
}

.title-text {
  font-weight: bold;
  margin-left: 8px;
  color: #2c3e50; /* Dark text for contrast */
}

.search-input {
  max-width: 300px;
}

/* Style the table headers and cells */
::v-deep .v-data-table-header th {
  background-color: #fafafa; /* Very light grey background */
  color: #2c3e50; /* Dark text for contrast */
  padding: 12px 24px; /* Comfortable padding */
}

::v-deep .v-data-table td {
  background-color: #fff; /* White background for rows */
  color: #2c3e50; /* Dark text for contrast */
  padding: 12px 24px; /* Comfortable padding */
}

/* Rounded search input field */
::v-deep .v-text-field--outlined .v-input__control .v-input__slot {
  border-radius: 20px; /* Fully rounded border */
}

::v-deep .v-text-field--outlined .v-input__append-inner {
  border-radius: 20px; /* Maintain rounded border on the append side */
}

/* Icon button for clearing search */
::v-deep .v-btn {
  border-radius: 50%; /* Round button */
}

/* Adjust search icon size and color */
::v-deep .mdi-magnify {
  font-size: 24px;
  color: #2c3e50; /* Dark icon for contrast */
}

/* Adjust close icon size and color */
::v-deep .mdi-close {
  font-size: 24px;
  color: #2c3e50; /* Dark icon for contrast */
}

/* Pagination styles, if needed */
::v-deep .v-pagination {
  /* Your styles here */
}
</style>
