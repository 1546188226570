<template>
  <div v-if="!loading" class="fill-height align-content-center align-center d-flex">
    <p v-if="isPresent" :style="`color: ${tColor}`" class="ma-0 pa-0">
      <span>
        {{ value }}
      </span>
      <span style="font-size: 1.5rem" v-if="withUnit">
        {{unit}}
      </span>
    </p>
    <div v-else class="d-flex fill-height">
      <v-chip disabled small>
        <v-icon small>mdi-alert-outline</v-icon>
        <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('general.ui.not_data_found') }}</p>
      </v-chip>
    </div>
  </div>
  <div v-else class="d-flex justify-start align-center fill-height">
    <v-chip small disabled>
      <v-progress-circular indeterminate v-if="loading" color="grey darken-1" size="14" width="3" />
      <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('widgets.loadData') }}</p>
    </v-chip>
  </div>
</template>

<script>
import LanguageService from "@/services/LanguajeService";
import NumberFormatService from "@/services/NumberFormatService";
import { FilterWidgetMixin } from "@/module/dashboard/mixins/filter-widget-mixin";

export default {
  name: "RenderValues",
  mixins: [FilterWidgetMixin],
  props: {
    initialized: {
      type: Boolean,
      default: false
    },
    tColor: {
      type: String,
      default: '#232323'
    },
    widgetId: {
      type: String,
      required: true
    },
    withUnit: {
      type: Boolean
    },
    applicableFilters: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data() {
    return {
      isPresent: false,
      value: 0,
      loading: false,
      unit: null,
      afterPoint: null,
      region: null
    };
  },
  // async created() {
  //   await this.fetchData(true);
  // },
  mounted() {
    this.$root.$on('apply:dashboard-filters', (checkOnCreate = false) => {
      this.fetchData(checkOnCreate);
    })
    this.$root.$on('on:recalculate', () => {
      this.recalculating = true;
      this.filterObjectByKeys(this.filters, this.applicableFilters);
    })
  },
  computed: {
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    async fetchData(checkOnCreated) {
      if (!this.isEditingEnabled) {
        if (checkOnCreated || this.initialized) {
          this.loading = true;
          const profile = JSON.parse(localStorage.getItem('profile'));
          console.log(`filters-${profile.company_id}-${this.layout.id}`, this.filters);
          const filters = this.filterObjectByKeys(this.filters, this.applicableFilters);
          await this.$store.dispatch('dashboard/fetchWidgetConfig', [this.widgetId, filters])
            .then(res => {
              if (res.reload) {
                this.$root.$emit('reload:layout', { reload: true, layoutId: this.layout.id });
              }

              if (this.withUnit) {
                this.unit = this.translate(res.unitShowed);
              }

              if (res.format) {
                this.afterPoint = res.format.afterPoint;
                this.region = res.format.region;
              }

              this.value = NumberFormatService.formatNumber(res.value, this.afterPoint, this.region);

              this.$emit('update:value', this.value);
              this.isPresent = true;
            })
            .catch(err => {
              console.log(err)
              this.isPresent = false;
            })
            .finally(() =>  this.loading = false)
        } else {
          this.loading = false;
        }
      } else {
        this.isPresent = false
        this.loading = false
      }
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
  },

  beforeDestroy() {
    this.$root.$off('on:recalculate')
    this.$root.$off('apply:dashboard-filters')
  }
}
</script>

<style scoped>
.v-chip {
  border-radius: .3em;
}
</style>