import { render, staticRenderFns } from "./EntityTable.vue?vue&type=template&id=95c779f2&scoped=true&"
import script from "./EntityTable.vue?vue&type=script&lang=js&"
export * from "./EntityTable.vue?vue&type=script&lang=js&"
import style0 from "./EntityTable.vue?vue&type=style&index=0&id=95c779f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95c779f2",
  null
  
)

export default component.exports