<template>
  <v-btn v-if="!isEditingEnabled" icon @click="reload">
    <v-icon :class="{ 'rotating': isLoading }">mdi-autorenew</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ReloadWidget",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    reload() {
      this.$emit('reload:requested');
    }
  }
}
</script>

<style scoped>
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.rotating {
  animation: spin 1s linear infinite;
}
</style>
