import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_vm._t("prepend"),_vm._t("append"),_c(VNavigationDrawer,{staticStyle:{"z-index":"1000"},attrs:{"value":_vm.drawer,"app":"","right":"","width":"300px"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('widgets.editMode.title'))+" "),_c(VSpacer),_c(VChip,{staticClass:"ma-0 px-2",attrs:{"small":"","label":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('widgets.editMode.keyCombination1'))+"   "),_c(VIcon,{staticClass:"shortcut-icon ma-0 pa-0",attrs:{"small":""}},[_vm._v("mdi-apple-keyboard-shift")]),_vm._v(" + F   "+_vm._s(_vm.$t('widgets.editMode.keyCombination2'))+" ")],1)],1),_c(VDivider),_c('SearchInput',{ref:"searchInput",staticClass:"ma-2",on:{"on-search":_vm.filterWidgets}}),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.filteredWidgets),function(widget,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c(VOverlay,{attrs:{"value":!_vm.isLayoutSelected,"color":"white","opacity":.1}},[_c(VChip,{staticClass:"pa-6 text-center",attrs:{"color":"primary","label":""}},[_vm._v(" "+_vm._s(_vm.$t('widgets.editMode.alert1'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('widgets.editMode.alert2'))+" ")])],1),_c(widget.component,{tag:"component"})],1)],1)}),1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }