<template>
  <ChartWrapper
    :chartConfig="chartConfig"
    :isValid="isValid"
    :isEditingEnabled="isEditingEnabled"
    :loading="loading"
    :ready="ready"
    @on:download="exportChart(chartConfig.title)"
    @reload:requested="reloadData"
    :item="item"
    :link="true"
  >
    <template #dialog>
      <ProductsByLocationsDialog @update:validation="checkValidation($event)" :item="item" @update:config="updateConfig($event)" />
    </template>

    <template #values>
      <RenderValues
        @update:values="updateChart($event)"
        @on:ready="ready = $event"
        ref="renderValues"
        :chart-options="chartOptions"
        :chart-series="chartSeries"
        :ready="ready"
        :widget-id="item.props.id"
        :t-color="chartConfig.color"
        :initialized="item.initialized"
        :applicable-filters="item.applicableFilters"
      />
    </template>
  </ChartWrapper>
</template>

<script>
  import ChartWrapper from "@/module/dashboard/components/widgets/chart/common/ChartWrapper.vue"
  import RenderValues from "@/module/dashboard/components/widgets/chart/common/RenderValues.vue"
  import { ChartWidgetMixin } from "@/module/dashboard/mixins/chart-widget.mixin"
  import { ReloadWidgetMixin } from "@/module/dashboard/mixins/reload-widget.mixin"
  import { ColumnMixin } from "@/module/dashboard/components/widgets/chart/column/mixins/column.mixin"
  import ProductsByLocationsDialog
    from "@/module/dashboard/components/widgets/chart/column/products_by_state/ProductsByStateDialog.vue"

  export default {
  components: {
    ProductsByLocationsDialog,
    ChartWrapper,
    RenderValues
  },
  mixins: [ChartWidgetMixin, ReloadWidgetMixin, ColumnMixin],
  methods: {
    updateChart(e) {
      const rangeDate = this.chartConfig.filter.rangeDate;

      this.chartOptions.xaxis.categories = rangeDate ? e.xAxis.map(value => this.getTranslation(`${rangeDate}.${value}`, value)) : e.xAxis;
      this.chartSeries[0].data = e.yAxis;

      this.$set(this, 'format', e.format);
      this.ready = true;
      this.$refs.renderValues.refreshChart();
    }
  }
}
</script>
