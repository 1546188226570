<template>
  <div class="search-bar">
    <div class="select-wrapper">
      <select v-model="selectedCategory" class="category-dropdown" @change="adjustWidth">
        <option v-for="category in categories" :value="category.value" :key="category.value">
          {{ category.name }}
        </option>
      </select>
    </div>
    <input
      ref="searchInput"
      type="search"
      v-model="searchQuery"
      :placeholder="$t('widgets.editMode.searchText')"
      class="search-input"
      :class="{ focused: isFocused }"
      @focus="onFocus"
      @blur="onBlur"
      @keyup.enter="emitSearch"
    />
    <button class="search-button" @click="emitSearch">
      <v-icon small color="white">mdi-magnify</v-icon>
    </button>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  data() {
    return {
      selectedCategory: 'all',
      searchQuery: '',
      categories: [
        {
          name: i18n.t('widgets.editMode.categories.all'),
          value: 'all'
        },
        {
          name: i18n.t('widgets.editMode.categories.card'),
          value: 'card'
        },
        {
          name: i18n.t('widgets.editMode.categories.table'),
          value: 'table'
        },
        {
          name: i18n.t('widgets.editMode.categories.chart'),
          value: 'chart'
        },
        {
          name: i18n.t('widgets.editMode.categories.map'),
          value: 'map'
        }
      ],
      isFocused: false
    };
  },
  mounted() {
    this.adjustWidth();
    // Agrega un listener para las teclas en el componente montado
    window.addEventListener('keydown', this.handleGlobalKeys);
  },
  beforeDestroy() {
    // Asegúrate de remover el listener para evitar fugas de memoria
    window.removeEventListener('keydown', this.handleGlobalKeys);
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    emitSearch() {
      this.$emit('on-search', { query: this.searchQuery, category: this.selectedCategory });
    },
    handleGlobalKeys(event) {
      // Verifica si es Shift + F o Control + Shift + F
      if (event.ctrlKey && event.shiftKey && event.key === 'F') {
        // Previene la acción por defecto para no interferir con otras funcionalidades del navegador
        event.preventDefault();
        // Enfoca el campo de búsqueda
        this.$refs.searchInput.focus();
      }
    },
    adjustWidth() {
      this.$nextTick(() => {
        const selectedOption = this.$el.querySelector('.category-dropdown option:checked');
        const dropdown = this.$el.querySelector('.category-dropdown');
        const tmpOption = document.createElement('span');
        tmpOption.textContent = selectedOption.textContent;
        tmpOption.style.display = 'inline-block';
        tmpOption.style.padding = '0.7rem';
        tmpOption.style.fontSize = '12px';
        document.body.appendChild(tmpOption);
        dropdown.style.width = `${tmpOption.clientWidth}px`;
        document.body.removeChild(tmpOption);
      });

      this.emitSearch()
    }
  }
};
</script>

<style scoped>
.search-bar {
  display: flex;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 30px;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.category-dropdown {
  padding: 0.5rem;
  background-color: #edf2f7;
  color: #4a5568;
  font-size: 10px;
  border-color: transparent;
}

.search-input {
  flex-grow: 1; /* toma el espacio restante */
  border: none;
  padding: 0.5rem;
  font-size: 12px;
  width: 100%;
}

.search-input.focused {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); /* Un resplandor azul para resaltar */
}

.search-input:focus {
  outline: none;
}

.search-button {
  padding: 0.5rem;
  background-color: #108aff; /* un azul claro */
  border: none;
  cursor: pointer;
}

.search-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: white; /* ícono blanco */
}
</style>
