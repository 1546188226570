<template>
  <div @dragend="dragend" @drag="drag" class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-chart" :style="borderColor">

      <ChipWidgetSubtype name="PRODUCT_PIE" icon="mdi-chart-pie" info="PRODUCT_PIE" />

      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div style="border: 1px dashed #e6e6e6; border-radius: 4px; width: 100%" class="d-flex justify-center flex-column align-center align-content-center pa-4">
        <div class="skeleton-chart-container" >
          <div class="skeleton-pie-section" v-for="i in 4" :key="i" :style="pieSectionStyle(i)"></div>
        </div>
        <div class="skeleton-legend-container">
          <div class="skeleton-legend-item" v-for="i in 2" :key="`legend-${i}`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";

export default {
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      data: {
        type: 'CHART',
        subtype: 'PRODUCT_PIE',
        props: {
          title: 'PRODUCT_PIE',
          values: {
            data: [20, 50],
            labels: ['Product 1', 'Product 2']
          },
          color: '#232323',
          icon: 'mdi-chart-pie',
          filter: {
            processIds: null,
            groupIds: null,
            nestedValue: []
          },
          initialized: false
        },
        w: 4,
        h: 9,
      }
    };
  },
  computed: {
    borderColor() {
      return {
        borderLeft: `4px solid ${this.color}60`
      };
    },
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },

  },
  methods: {
    drag(e) {
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    },
    pieSectionStyle(index) {
      const degreesPerSection = 360 / 4; // Grados por sección para un círculo completo
      const start = degreesPerSection * (index - 1); // El ángulo de inicio de la sección
      const end = start + degreesPerSection; // El ángulo final de la sección
      return {
        background: `conic-gradient(
          #e0e0e0 ${start}deg,
          #f0f0f0 ${start + 0.01}deg,
          #f0f0f0 ${end - 0.01}deg,
          #e0e0e0 ${end}deg
        )`,
        borderRadius: '50%',
        width: '100%',
        height: '100%',
        position: 'absolute',
        clipPath: `polygon(50% 50%, 100% 0, 100% 100%)`,
      };
    },
  }
}
</script>

<style scoped>
@import "../../../../../styles/scss/components/_skeleton.scss";
@import "../../../../../styles/scss/components/skeletons/_chart.scss";

/*.skeleton-pie-section {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.skeleton-legend-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px; !* Espacio por encima de la leyenda *!
}

.skeleton-legend-item {
  width: 30px; !* Ancho del ítem de la leyenda *!
  height: 10px; !* Altura del ítem de la leyenda *!
  background-color: #e0e0e0; !* Color del ítem de la leyenda *!
  border-radius: 4px; !* Bordes redondeados del ítem de la leyenda *!
  margin: 0 4px; !* Espacio alrededor del ítem de la leyenda *!
}*/
</style>
