<template>
  <div @dragend="dragend" @drag="drag" class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-card" :style="borderColor">

      <ChipWidgetSubtype :name="$t('widgets.names.AGTRACE_SUM_BY_UNIT')" icon="mdi-card-bulleted" :info="$t('widgets.descriptions.AGTRACE_SUM_BY_UNIT')" />

      <p class="ma-0 pa-0 font-weight-bold" :style="`color: ${data.color}80`"></p>
      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div class="skeleton-content" :style="valueColor"></div>
    </div>
  </div>
</template>

<script>
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";
import i18n from "@/plugins/i18n";

export default {
  name: 'SumSkeleton',
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      data: {
        type: 'CARD',
        subtype: 'AGTRACE_SUM_BY_UNIT',
        props: {
          title: i18n.t('widgets.names.AGTRACE_SUM_BY_UNIT'),
          values: [],
          icon: 'mdi-card-bulleted',
          color: '#232323',
          filter: {
            processIds: [],
            groupIds: [],
            nestedValues: [],
            unitSelected: null
          }
        },
        w: 4,
        h: 3,
        initialized: false,
        valid: false
      }
    };
  },
  computed: {
    borderColor() {
      return {
        borderLeft: `4px solid ${this.data.color}60`
      };
    },
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },
    valueColor() {
      return {
        color: this.data.color
      };
    }
  },
  methods: {
    drag(e) {
      // Emit an event with the information necessary to create the new item
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    }
  }
};
</script>

<style scoped>
@import "../../../../styles/scss/components/_skeleton.scss";
@import "../../../../styles/scss/components/skeletons/_card.scss";
</style>
