<template>
  <v-card id="chart" class="data-chart-card" flat height="100%" :class="{ 'card__errored' : !isValid && isEditingEnabled }" >
    <v-card-title class="d-flex align-center ma-2 mr-0">
      <v-icon left :color="chartConfig.color">{{ chartConfig.icon }}</v-icon>
      <span class="title-text show__filter-data d-flex align-center" v-if="!isEditingEnabled">
        <v-tooltip content-class="title__tooltip" top>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="cursor-pointer" @click="$refs.toFilterData.toData()">{{ chartConfig.title | truncate(18) }}</div>
          </template>
          <span>{{ chartConfig.title }}</span>
        </v-tooltip>
        <v-tooltip bottom content-class="custom__tooltip">
          <template v-slot:activator="{ on }">
            <v-chip v-show="(appliedFiltersCount > 0 && !recalculating) && !isEditingEnabled" class="custom__chip grey lighten-3 pa-1" small
                    v-on="on">
              <v-icon small>mdi-filter</v-icon>
              {{ appliedFiltersCount }}
            </v-chip>
          </template>
          <span v-html="appliedFiltersList"></span>
        </v-tooltip>
        <v-icon class="mb-1 icon__filter" v-if="link">mdi-arrow-right</v-icon>
      </span>
      <span class="title-text show__filter-data d-flex align-center" v-else>
        <v-tooltip content-class="title__tooltip" top>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="cursor-pointer">{{ chartConfig.title | truncate(18) }}</div>
          </template>
          <span>{{ chartConfig.title }}</span>
        </v-tooltip>
      </span>
      <v-spacer />

      <slot name="actions">
        <ShowFilteredData ref="toFilterData" id="show-filters" :config="chartConfig" :item="item" />
      </slot>
      <ReloadWidget :isLoading="loading" @reload:requested="$emit('reload:requested')" />
      <v-menu offset-y nudge-left="150" nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" :disabled="!ready" v-if="!isEditingEnabled">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="$emit('on:download')">
            <v-list-item-icon>
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ $t('widgets.export') }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <slot name="dialog"></slot>
    </v-card-title>
    <v-card-text style="height: calc(100% - 88px) !important;">
      <v-container fluid class="fill-height ma-0 pa-0">
        <v-row class="fill-height">
          <v-col class="fill-height ma-0 pa-4 pt-0">
            <slot name="values"></slot>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import ReloadWidget from "@dashboard/components/common/ReloadWidget.vue";
  import {FilterWidgetMixin} from "@/module/dashboard/mixins/filter-widget-mixin";
  import ShowFilteredData from "@/module/dashboard/components/common/ShowFilteredData.vue";

  export default {
    components: {
      ShowFilteredData,
      ReloadWidget
    },
    mixins: [FilterWidgetMixin],
    props: {
      link: {
        type: Boolean,
        default: true,
        required: false
      },
      chartConfig: Object,
      isValid: Boolean,
      ready: Boolean,
      isEditingEnabled: Boolean,
      loading: Boolean,
      item: Object
    }
  }
</script>

<style scoped>
  @import url('../../../../styles/scss/components/_chart.scss');
  .v-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    overflow: auto !important;

    .v-card__text {
      padding-bottom: 0;
    }
  }

  .v-menu__content,
  .v-sheet.v-list:not(.v-sheet--outlined) {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .title__tooltip {
    width: auto;
    background-color: rgba(0, 0, 0) !important;
    color: white;
  }

  .custom__tooltip {
    background-color: #000 !important;
    color: #fff !important;
    font-size: 0.875rem;
    border-radius: 4px;
    padding: 8px 10px;
  }

  .show__filter-data {
    display: inline-flex;
    position: relative;
  }

  .show__filter-data .icon__filter {
    opacity: 0;
    left: -20px
  }

  .show__filter-data:hover .icon__filter {
    opacity: 1;
    left: 0
  }
</style>
