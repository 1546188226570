<template>
  <div @dragend="dragend" @drag="drag"  class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-table" :style="borderColor">

      <ChipWidgetSubtype :name="$t('widgets.names.MAP_BY_LAYERS')" icon="mdi-map" :info="$t('widgets.descriptions.MAP_BY_LAYERS')" />

      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div class="skeleton-wrapper">
        <div class="skeleton-card pa-2">
          <div class="skeleton-map">
            <!-- Inserción del archivo SVG como una imagen -->
            <img src="../../../../assets/images/shokunin_World_Map.svg" alt="Map Skeleton" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";


export default {
  name: 'MapSkeleton',
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      data: {
        type: 'CARD',
        subtype: 'MAP_BY_LAYERS',
        props: {
          title: i18n.t('widgets.names.MAP_BY_LAYERS'),
          values: [],
          icon: 'mdi-card-bulleted',
          color: '#232323',
          filter: {
            nestedValues: []
          }
        },
        w: 12,
        h: 18,
        isResizable: false,
        initialized: false
      }
    };
  },
  computed: {
    borderColor() {
      return {
        borderLeft: `4px solid ${this.data.color}60`
      };
    },
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },
  },
  methods: {
    drag(e) {
      // Emit an event with the information necessary to create the new item
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    },
    barStyle(percentage) {
      return {
        width: `${percentage}%`,
        backgroundColor: percentage > 0 ? '#e0e0e0' : 'transparent',
        height: '20px',
        borderRadius: '4px',
        marginBottom: '4px',
      };
    }
  }
};
</script>

<style scoped>
@import "../../../../styles/scss/components/_skeleton.scss";
@import "../../../../styles/scss/components/skeletons/_table.scss";
@import "../../../../styles/scss/components/skeletons/_statics.scss";
</style>
