<template>
  <div @dragend="dragend" @drag="drag" class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-chart" :style="borderColor">

      <ChipWidgetSubtype :name="$t('widgets.names.COUNT_PROCESS_BY_LOCATION')" icon="mdi-chart-box-outline" :info="$t('widgets.descriptions.COUNT_PROCESS_BY_LOCATION')" />

      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div class="skeleton-chart-container pa-2" style="border: 1px dashed #e6e6e6; border-radius: 4px">
        <div class="skeleton-bar-horizontal" v-for="i in 4" :key="i" :style="barWidth(i)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";
import i18n from "@/plugins/i18n";


export default {
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      data: {
        type: 'CHART',
        subtype: 'COUNT_PROCESS_BY_LOCATION',
        props: {
          title: i18n.t('widgets.names.COUNT_PROCESS_BY_LOCATION'),
          values: {
            xAxis: ['AAA', 'BBB', 'CCC', 'DDD', 'EEE', 'FFF', 'GGG'],
            yAxis: [12,24,34,54,12,40,100]
          },
          color: '#232323',
          icon: 'mdi-chart-box-outline',
          filter: {
            processIds: null,
            locationPosition: null,
            locationType: null,
            direction: null
          },
        },
        w: 5,
        h: 10,
        initialized: false,
        valid: false
      }
    };
  },
  computed: {
    borderColor() {
      return {
        borderLeft: `4px solid ${this.data.color}60`
      };
    },
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },
  },
  methods: {
    drag(e) {
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    },
    barWidth(index) {
      // Crear anchuras variadas para simular barras de diferentes longitudes
      const widths = ['70%', '50%', '80%', '20%', '60%', '50%', '60%', '80%'];
      return {
        width: widths[index % widths.length],
        backgroundColor: '#e0e0e0',
      };
    }
  }
}
</script>

<style scoped>
@import "../../../../../styles/scss/components/_skeleton.scss";
@import "../../../../../styles/scss/components/skeletons/_chart.scss";
</style>
