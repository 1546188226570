<template>
  <div @dragend="dragend" @drag="drag"  class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-table" :style="borderColor">

      <ChipWidgetSubtype :name="$t('widgets.names.CITY_QR_VISIT')" icon="mdi-table" :info="$t('widgets.descriptions.CITY_QR_VISIT')"  />

      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div class="skeleton-header"></div>
      <div class="skeleton-row" v-for="i in 3" :key="i">
        <div class="skeleton-cell" v-for="j in 3" :key="`cell-${j}`"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";
import i18n from "@/plugins/i18n";

export default {
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      data: {
        type: 'TABLE',
        subtype: 'CITY_QR_VISIT',
        props: {
          title: i18n.t('widgets.names.CITY_QR_VISIT'),
          values: [],
          color: '#232323',
          icon: 'mdi-grid'
        },
        w: 8,
        h: 14,
        initialized: false,
        valid: false
      }
    };
  },
  computed: {
    borderColor() {
      return {
        borderLeft: `4px solid ${this.data.color}60`
      };
    },
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },
  },
  methods: {
    drag(e) {
      // Emite un evento global con la información necesaria para crear el nuevo ítem
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    }
  }
}
</script>

<style scoped>
@import "../../../../styles/scss/components/_skeleton.scss";
@import "../../../../styles/scss/components/skeletons/_table.scss";
@import "../../../../styles/scss/components/skeletons/_statics.scss";
</style>
