import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"d-flex"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VTextField,_vm._g({attrs:{"label":_vm.label,"append-icon":"mdi-calendar","readonly":"","clearable":true,"dense":"","flat":"","outlined":"","color":"secondary","hide-details":""},on:{"click:clear":_vm.clearDates,"click:append":_vm.toggleMenu},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"color":"secondary","range":"","min":_vm.minDate,"locale":_vm.$i18n.locale,"selected-items-text":'{0} ' + _vm.$t('records.filters.selected_items_text')},on:{"input":_vm.updateDates},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"gray"},on:{"click":_vm.clearDates}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.clean'))+" ")]),_c(VBtn,{attrs:{"disabled":_vm.dates.length < 2,"text":"","color":"secondary"},on:{"click":_vm.confirmDates}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.confirm'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }