<template>
  <v-card class="data-table-card" flat style="height: 100%" :class="{ 'card__errored' : !isValid && isEditingEnabled }" >
    <v-card-title class="d-flex align-center ma-2 mr-0">
      <v-icon left :color="tableConfig.color">{{ tableConfig.icon }}</v-icon>
      <div class="d-flex flex-column">
        <span class="title-text show__filter-data d-flex align-center justify-start" v-if="!isEditingEnabled">
          <v-tooltip content-class="title__tooltip" top>
            <template v-slot:activator="{ on }">
            <div v-on="on" class="cursor-pointer" v-if="!isEditingEnabled" @click="$refs.toFilterData.toData()">{{ tableConfig.title | truncate(18) }}</div>
            <div v-on="on" class="cursor-pointer" v-else>{{ tableConfig.title | truncate(18) }}</div>
            </template>
            <span>{{ tableConfig.title }}</span>
          </v-tooltip>
          <v-tooltip bottom content-class="custom__tooltip">
            <template v-slot:activator="{ on }">
              <v-chip v-show="(appliedFiltersCount > 0 && !recalculating) && !isEditingEnabled" class="custom__chip grey lighten-3 pa-1" small
                      v-on="on">
                <v-icon small>mdi-filter</v-icon>
                {{ appliedFiltersCount }}
              </v-chip>
            </template>
            <span v-html="appliedFiltersList"></span>
          </v-tooltip>
          <v-icon class="mb-1 icon__filter" v-if="link">mdi-arrow-right</v-icon>
        </span>
        <span class="title-text show__filter-data d-flex align-center" v-else>
          <v-tooltip content-class="title__tooltip" top>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="cursor-pointer">{{ tableConfig.title | truncate(18) }}</div>
            </template>
            <span>{{ tableConfig.title }}</span>
          </v-tooltip>
        </span>
      </div>
      <v-spacer></v-spacer>

      <slot name="dialog"></slot>
      <slot name="actions">
        <ShowFilteredData ref="toFilterData" id="show-filters" :config="tableConfig" :item="item" />
      </slot>
      <ReloadWidget :isLoading="loading" @reload:requested="$emit('reload:requested')" />
    </v-card-title>
    <v-card-text style="height: calc(100% - 140px) !important;">
      <slot name="values"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import ReloadWidget from "@/module/dashboard/components/common/ReloadWidget.vue";
import {FilterWidgetMixin} from "@/module/dashboard/mixins/filter-widget-mixin";
import ShowFilteredData from "@/module/dashboard/components/common/ShowFilteredData.vue";

export default {
  components: {ShowFilteredData, ReloadWidget},
  mixins: [FilterWidgetMixin],
  props: {
    link: {
      type: Boolean,
      default: true,
      required: false
    },
    tableConfig: Object,
    isValid: Boolean,
    ready: Boolean,
    isEditingEnabled: Boolean,
    loading: Boolean,
    item: Object
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  overflow: auto !important;

  .v-card__text {
    padding-bottom: 0;
  }
}

.data-table-card {
  border-radius: 8px;
  overflow: hidden;
}

.title-text {
  font-weight: bold;
  margin-left: 8px;
  color: #2c3e50; /* Dark text for contrast */
}

.search-input {
  max-width: 300px;
}

.title__tooltip {
  width: auto;
  max-width: 20%;
  background-color: rgba(0, 0, 0) !important;
  color: white;
}

.show__filter-data {
  display: inline-flex;
  position: relative;
}

.show__filter-data .icon__filter {
  opacity: 0;
  left: -20px
}

.show__filter-data:hover .icon__filter {
  opacity: 1;
  left: 0
}
</style>
