import NumberFormatService from "@/services/NumberFormatService";

export const DotsMixin = {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'area',
          stacked: false,
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              zoom: false,
              download: true
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            }
          },
        },
        theme: {
          monochrome: {
            color: `${this.item.props.color}`,
            enabled: true
          }
        },
        labels: this.item.props.values[0].xAxis,
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        plotOptions: {
          area: {
            distributed: false
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint, this.format.region)
            }
          },
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint, this.format.region)
            }
          }
        },
      },
      chartSeries: [{
        name: this.item.props.title,
        data: this.item.props.values[0].yAxis
      }],
      format: {
        afterPoint: null,
        region: null,
      },
    };
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    updateChart(e) {
      this.chartOptions.labels = e.xAxis;
      this.chartSeries[0].data = e.yAxis;

      this.$set(this, 'format', e.format);

      this.ready = true;
      this.$refs.renderValues.refreshChart();
    },
  },
};
