<template>
  <div @dragend="dragend" @drag="drag" class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-chart" :style="borderColor">

      <ChipWidgetSubtype name="ENTITY_BAR" icon="mdi-chart-box-outline" info="ENTITY_BAR" />

      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div class="skeleton-chart-container pa-2" style="border: 1px dashed #e6e6e6; border-radius: 4px">
        <div class="skeleton-bar" v-for="i in 10" :key="i" :style="barHeight(i)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";

export default {
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      data: {
        type: 'CHART',
        subtype: 'ENTITY_BAR',
        props: {
          title: 'ENTITY_BAR',
          values: {
            xAxis: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            yAxis: [12,24,34,54,12,38,76,56,43,34,23,100]
          },
          color: this.color,
          icon: 'mdi-chart-box-outline',
          filter: {
            entity: null,
          },
        },
        w: 4,
        h: 9,
        initialized: false
      }
    };
  },
  computed: {
    borderColor() {
      return {
        borderLeft: `4px solid ${this.color}60`
      };
    },
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },
  },
  methods: {
    drag(e) {
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    },
    barHeight(index) {
      // Crear alturas variadas para simular barras de diferentes tamaños
      const heights = ['70%', '50%', '80%', '20%', '60%', '50%', '60%', '80%'];
      return {
        height: heights[index % heights.length],
        backgroundColor: '#e0e0e0',
      };
    }
  }
}
</script>

<style scoped>
@import "../../../../../styles/scss/components/_skeleton.scss";
@import "../../../../../styles/scss/components/skeletons/_chart.scss";
</style>
