<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on:tooltip }">
      <v-menu
        v-on="tooltip"
        bottom
        right
        v-model="menuVisible"
        :close-on-click="true"
        :close-on-content-click="true"
      >
        <template v-slot:activator="{ on:menu }">
          <v-btn
            v-on="menu"
            text
            icon
            small
            @click="toggleMenu"
            :disabled="!layout.id"
          >
            <v-icon
              class="mx-1"
              color="neuter"
              size="20"
            >
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list elevation="0" dense>
          <v-list-item @click="closeMenu" v-if="layout.user.isOwner">
            <SendDialog :layout="layout" @close="closeMenu" />
          </v-list-item>
          <v-list-item @click="closeMenu" v-if="layout.user.isOwner">
            <ShareDialog :layout="layout" @close="closeMenu" />
          </v-list-item>
          <v-list-item @click="closeMenu" v-if="grant('COPY')">
            <v-btn class="show__btn" small text @click.stop="cloneDashboard">
              <template v-if="loadings.cloneDashboard">
                <v-progress-circular
                  indeterminate
                  color="black"
                  size="13"
                  width="2"
                  class="mr-2"
                ></v-progress-circular>
              </template>
              <template v-else>
                <v-icon left small color="black">mdi-content-copy</v-icon>
              </template>
              {{ $t('dashboard.buttons.clone') }}
            </v-btn>
          </v-list-item>
          <v-list-item @click="closeMenu">
            <v-btn class="show__btn" small text @click.stop="setDefault" :disabled="layout.selected">
              <template v-if="loadings.setDefault">
                <v-progress-circular
                  indeterminate
                  color="black"
                  size="13"
                  width="2"
                  class="mr-2"
                ></v-progress-circular>
              </template>
              <template v-else>
                <v-icon left small :color="layout.selected ? 'yellow' : 'black'">{{ layout.selected ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
              </template>
              {{ $t('dashboard.buttons.setDefault') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <span>
      {{ $t('general.buttons.options') }}
    </span>
  </v-tooltip>
</template>

<script>
  import SendDialog from './SendDialog.vue';
  import ShareDialog from './ShareDialog.vue';
  import { mapGetters } from "vuex"

  export default {
    name: 'MoreOptions',
    components: {
      SendDialog,
      ShareDialog
    },
    data() {
      return {
        menuVisible: false,
        loadings: {
          setDefault: false,
          cloneDashboard: false
        }
      };
    },
    computed: {
      ...mapGetters({
        layouts: 'dashboard/LAYOUTS',
        layout: "dashboard/LAYOUT"
      })
    },
    methods: {
      async setDefault() {
        this.loadings.setDefault = true;
        await this.$store.dispatch('dashboard/setDefault', this.layout.id).then(() => {
          const layouts = [...this.layouts];
          _.forEach(layouts, (l) => {
            l.selected = l.id === this.layout.id;
          })

          this.$store.commit('dashboard/SET_LAYOUTS', layouts);
          this.$store.commit('dashboard/SET_LAYOUT', this.layout);
          this.$store.commit('dashboard/SET_WIDGETS', this.layout.widgets);
        }).finally(() => {
          this.loadings.setDefault = false;
          this.menuVisible = false;
        });
      },
      async cloneDashboard() {
        this.loadings.cloneDashboard = true;
        await this.$store.dispatch('dashboard/cloneDashboard', this.layout.id).then(() => {
          this.$root.$emit('reload:layout', {
            reload: true,
            layoutId: this.layout.id
          })
        }).finally(() => {
          this.loadings.cloneDashboard = false;
          this.menuVisible = false;
        });
      },
      grant(permission) {
        return this.layout.user.permissions.includes(permission);
      },
      toggleMenu() {
        this.menuVisible = !this.menuVisible;
      },
      closeMenu() {
        this.menuVisible = false;
      }
    }
  };
</script>

<style scoped>
  .v-menu__content {
    border-radius: .5em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    margin-top: 10px;
  }

  .show__btn.v-btn {
    width: 100%;
  }

  >>> span.v-btn__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
</style>
