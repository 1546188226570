<template>
  <v-card class="data-chart-card" flat style="height: 100%">
    <v-card-title class="d-flex align-center ma-2 mr-0">
      <v-icon left :color="chartConfig.color">{{ chartConfig.icon }}</v-icon>
      <span class="title-text">{{ chartConfig.title }}</span>
      <v-spacer />
      <EntityDialog :item="item" @update:config="updateConfig($event)" />
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col>
            <div id="chart">
              <apexchart
                ref="chart"
                v-if="ready"
                type="bar"
                :options="chartOptions"
                :series="chartSeries"
                height="200"
              ></apexchart>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import EntityDialog from "@dashboard/components/widgets/chart/bar/entity/EntityDialog.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    EntityDialog,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      ready: false,
      chartConfig: {
        title: this.item.props.title,
        icon: this.item.props.icon,
        color: this.item.props.color,
        values: this.item.props.values,
        filter: this.item.props.filter
      },
      chartOptions: {
        chart: {
          type: 'bar',
          height: 200,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          labels: {
            trim: true
          },
          categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 4
          }
        },
        colors: [`${this.item.props.color}`],
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        legend: {
          show: false
        },
      },
      chartSeries: [{
        name: this.item.props.title,
        data: this.item.props.values.yAxis || []
      }]
    }
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    updateConfig(newConfig) {
      this.chartConfig = { ...this.chartConfig, ...newConfig };

      this.chartConfig.id = this.id;
      this.chartConfig.title = newConfig.title;
      this.chartConfig.icon = newConfig.icon;
      this.chartConfig.color = newConfig.color;
      this.chartConfig.filter = {
        entity: newConfig.filter.entity,
        headers: newConfig.filter.headers
      };

      this.$set(this.chartOptions, 'colors', [`${this.chartConfig.color}`])
      this.$refs.chart.refresh();

      this.$emit('update:config', this.chartConfig);
    }
  }
}
</script>

<style scoped>
.v-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  overflow: auto !important;

  .v-card__text {
    padding-bottom: 0;
  }
}

#chart {
  max-width: 100%;
}

.data-chart-card {
  border-radius: 8px;
  overflow: hidden;
}

.title-text {
  font-weight: bold;
  margin-left: 8px;
  color: #2c3e50; /* Dark text for contrast */
}
</style>
