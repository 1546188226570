class NumberFormatService {
  constructor() {}

  formatNumber(number, afterPoint, region) {
    const formatter = new Intl.NumberFormat(region.toLowerCase(), {
      minimumFractionDigits: afterPoint,
      maximumFractionDigits: afterPoint
    });

    if(region.toLowerCase() === 'es-es') {
      return this.formatNumberForSpain(number, afterPoint);
    }

    return formatter.format(number);
  }

  formatNumberSeries(numbersArray, afterPoint, region) {
    return numbersArray.map(number => this.formatNumber(number, afterPoint, region.toLowerCase()));
  }

  formatNumberForSpain(number, afterPoint) {
    let [integerPart, decimalPart] = number.toString().split(".");

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (decimalPart) {
      decimalPart = parseFloat("0." + decimalPart).toFixed(afterPoint).split(".")[1];
    } else {
      decimalPart = ''.padEnd(afterPoint, '0');
    }

    return `${integerPart},${decimalPart}`;
  }
}

export default new NumberFormatService();
