
export const TableWidgetMixin = {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            search: '',
            isValid: this.item.valid,
            tableConfig: {
                title: this.item.props.title,
                icon: this.item.props.icon,
                color: this.item.props.color,
                values: this.item.props.values,
                filter: this.item.props.filter,
                // format: {...newConfig.format}
            }
        };
    },
    created() {
        this.$set(this.tableConfig, 'id', this.item.props.id)
        this.$emit("check:validations", this.isValid)
    },
    computed: {
        isEditingEnabled() {
            return this.$store.getters['dashboard/IS_EDITING_MODE'];
        }
    },
    methods: {
        checkValidation(e) {
            this.isValid = e;
            this.$emit('check:validations', e)
        },
        updateConfig(newConfig) {
            this.$set(this, 'tableConfig', {
                ...newConfig,
                id: this.item.props.id || newConfig.id,
                title: newConfig.title,
                icon: newConfig.icon,
                color: newConfig.color,
                filter: {...newConfig.filter},
                // format: {...newConfig.format}
            })

            this.$emit('update:card', this.tableConfig);
        }
    }
};
