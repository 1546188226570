import { default as http } from '@/services/HttpService';

class ProcessService {
    fetchGroups(payload) {
        return http.client.post('/v1/structure-group/process_group_list_all', payload);
    }

    fetchStructures(payload) {
        return http.client.post('/v1/structure/process_structure_list_all', payload);
    }

    fetchDataToSelect(payload) {
        return http.client.post('/v1/process/process_data_select_all', payload);
    }
}

export default new ProcessService();
