<template>
  <v-row class="flex-column" justify="start" align="start">
      <span class="mx-4">
        {{$t("widgets.dialogs.fields.color")}}
      </span>
    <v-col style="min-width: 220px;">
      <v-text-field v-model="color" hide-details class="ma-0 pa-0 elevation-0" solo persistent-hint>
        <template v-slot:append>
          <v-menu class="elevation-0" v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <div :style="swatchStyle" v-on="on" />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="color" flat mode.sync="rgb" />
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    current: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    color: null,
    menu: false
  }),
  mounted() {
    this.color = this.current
  },
  computed: {
    swatchStyle() {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  watch: {
    color(newV, oldV) {
      this.$emit('input', newV)
    }
  }
}
</script>

<style scoped>
:deep(.v-color-picker__alpha) {
  display: none !important;
}
:deep(.v-color-picker__preview:not(.v-color-picker__preview--hide-alpha) .v-color-picker__hue) {
  margin-bottom: 0 !important;
}
:deep(.v-input__slot) {
  box-shadow: none !important;
  border: 1px solid #9d9d9d;
}
</style>