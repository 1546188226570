<template>
  <v-card :class="{ 'card__errored' : !isValid && isEditingEnabled }" :style="`border-left: 4px solid ${cardConfig.color}60`" class="card-widget bg-white"
          height="100%">
    <v-card-title>
      <v-icon left :color="cardConfig.color">{{ cardConfig.icon }}</v-icon>

      <span class="title-text show__filter-data d-flex align-center" v-if="!isEditingEnabled">
        <v-tooltip content-class="title__tooltip" top>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="cursor-pointer" v-if="!isEditingEnabled" @click="$refs.toFilterData.toData()">{{ cardConfig.title | truncate(18) }}</div>
            <div v-on="on" class="cursor-pointer" v-else>{{ cardConfig.title | truncate(18) }}</div>
          </template>
          <span>{{ cardConfig.title }}</span>
        </v-tooltip>
        <v-tooltip bottom content-class="custom__tooltip">
          <template v-slot:activator="{ on }">
            <v-chip v-show="(appliedFiltersCount > 0 && !recalculating) && !isEditingEnabled" class="custom__chip grey lighten-3 pa-1" small
                    v-on="on">
              <v-icon small>mdi-filter</v-icon>
              {{ appliedFiltersCount }}
            </v-chip>
          </template>
          <span v-html="appliedFiltersList"></span>
        </v-tooltip>
        <v-icon class="mb-1 icon__filter" v-if="link">mdi-arrow-right</v-icon>
      </span>
      <span class="title-text show__filter-data d-flex align-center" v-else>
        <v-tooltip content-class="title__tooltip" top>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="cursor-pointer">{{ cardConfig.title | truncate(18) }}</div>
          </template>
          <span>{{ cardConfig.title }}</span>
        </v-tooltip>
      </span>
      <v-spacer></v-spacer>
      <slot name="dialog"></slot>
      <slot name="actions">
        <ShowFilteredData ref="toFilterData" id="show-filters" :config="cardConfig" :item="item" />
      </slot>
      <ReloadWidget :isLoading="loading" @reload:requested="$emit('reload:requested')"/>
    </v-card-title>
    <v-card-text class="value d-flex align-center align-content-center justify-space-between"
                 style="height: calc(100% - 84px);">
      <slot name="values"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import ReloadWidget from "@dashboard/components/common/ReloadWidget.vue";
import {FilterWidgetMixin} from "@/module/dashboard/mixins/filter-widget-mixin";
import ShowFilteredData from "@/module/dashboard/components/common/ShowFilteredData.vue";
import i18n from "@/plugins/i18n"
import { mapGetters } from "vuex"

export default {
  name: "CardWrapper",
  components: {ShowFilteredData, ReloadWidget},
  mixins: [FilterWidgetMixin],
  props: {
    link: {
      type: Boolean,
      default: true,
      required: false
    },
    cardConfig: Object,
    isValid: Boolean,
    ready: Boolean,
    isEditingEnabled: Boolean,
    loading: Boolean,
    item: Object
  },
  computed: {
    appliedFiltersCount() {
      const applicableFilters = this.item.applicableFilters || [];
      const appliedFilters = this.filterObjectByKeys(this.filters, applicableFilters);
      let count = 0;

      for (let key in appliedFilters) {
        if (appliedFilters.hasOwnProperty(key)) {
          const value = appliedFilters[key];
          const globalFilterType = this.filters.locationType;
          const entityType = this.item.props.filter.entity;

          if (this.isFilterApplicable(key, globalFilterType, entityType)) {
            if (value !== null && Array.isArray(value) && value.length > 0) {
              count++;
            }
          }
        }
      }

      return count;
    },
    appliedFiltersList() {
      const applicableFilters = this.item.applicableFilters || [];
      const appliedFilters = this.filterObjectByKeys(this.filters, applicableFilters);
      const appliedKeys = [];

      for (let key in appliedFilters) {
        if (appliedFilters.hasOwnProperty(key)) {
          const value = appliedFilters[key];
          const globalFilterType = this.filters.locationType;
          const entityType = this.item.props.filter.entity;

          if (this.isFilterApplicable(key, globalFilterType, entityType)) {
            if (value !== null && Array.isArray(value) && value.length > 0) {
              appliedKeys.push(i18n.t(`widgets.filters.${key}`));
            }
          }
        }
      }

      return appliedKeys.join('<br>');
    },
  }
};
</script>

<style scoped>
.v-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  overflow: auto !important;

  .v-card__text {
    padding-bottom: 0;
  }
}

.card-widget {
  border-radius: 4px;
  transition: box-shadow .3s ease-in-out;
  color: #424242;
}

.card-widget:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* slightly more prominent on hover */
}

.value {
  font-size: 2.5rem;
  font-weight: bold;
}

.title__tooltip {
  width: auto;
  background-color: rgba(0, 0, 0) !important;
  color: white;
}

.custom__tooltip {
  background-color: #000 !important;
  color: #fff !important;
  font-size: 0.875rem;
  border-radius: 4px;
  padding: 8px 10px;
}

.show__filter-data {
  display: inline-flex;
  position: relative;
}

.show__filter-data .icon__filter {
  opacity: 0;
  left: -20px
}

.show__filter-data:hover .icon__filter {
  opacity: 1;
  left: 0
}
</style>
