<template>
  <MapWrapper
    :cardConfig="cardConfig"
    :isValid="isValid"
    :isEditingEnabled="isEditingEnabled"
    :loading="loading"
    :item="item"
    :link="false"
    @reload:requested="reloadData"
  >
    <template #values>
      <RenderValues :applicable-filters="item.applicableFilters" ref="renderValues" :widget-id="item.props.id" :t-color="cardConfig.color" :initialized="item.initialized" :layers="layers" />
    </template>
    <template #dialog>
      <LocationDialog @update:validation="checkValidation($event)" :item="item" @update:config="updateConfig($event)" />
    </template>
  </MapWrapper>
</template>

<script>
  import { MapWidgetMixin } from "@/module/dashboard/mixins/map-widget.mixin";
  import { ReloadWidgetMixin } from "@/module/dashboard/mixins/reload-widget.mixin";
  import MapBox from "@/components/vueMapbox/MapBox.vue"
  import LocationDialog from "@/module/dashboard/components/widgets/map/locations/LocationDialog.vue";
  import MapWrapper from "@/module/dashboard/components/widgets/map/common/MapWrapper.vue"
  import ShowFilteredData from "@/module/dashboard/components/common/ShowFilteredData.vue";
  import RenderValues from "@/module/dashboard/components/widgets/map/common/RenderValues.vue"

  export default {
    name: "CountCard",
    components: { RenderValues, MapWrapper, ShowFilteredData, LocationDialog, MapBox },
    mixins: [MapWidgetMixin, ReloadWidgetMixin],
  };
</script>

<style scoped>
  .v-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    overflow: auto !important;

    .v-card__text {
      padding-bottom: 0;
    }
  }

  .card-widget {
    border-radius: 4px;
    transition: box-shadow .3s ease-in-out;
    color: #424242;
  }

  .card-widget:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* slightly more prominent on hover */
  }

  .value {
    font-size: 2.5rem;
    font-weight: bold;
  }
</style>
