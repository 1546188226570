import { mapGetters } from 'vuex';
import { getByProperty } from '@/utils';

export const ClassificationTypeMixin = {
  computed: {
    ...mapGetters({
      sClassifications: 'storage/GET_CLASSIFICATIONS',
      lClassifications: 'locations/GET_CLASSIFICATIONS',
    }),
    currentClassifications() {
      let filterEntity = this.config.filter[this.filterKey];

      if ((typeof filterEntity === 'object') && (filterEntity && filterEntity.key)) {
        filterEntity = filterEntity.key;
      }

      console.log(filterEntity);

      if (filterEntity === 'StorageDevice' || filterEntity === 'SECONDARY') {
        return this.sClassifications;
      } else if (filterEntity === 'Location' || filterEntity === 'PRIMARY') {
        return this.lClassifications;
      }
      return [];
    }
  },
  methods: {
    findClassificationById(id) {
      const allClassifications = [...this.sClassifications, ...this.lClassifications];
      return allClassifications.find(classification => classification.id === id)?id:null;
    },
    getByProperty
  }
};
