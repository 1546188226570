import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"data-table-card",staticStyle:{"height":"100%"},attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-flex align-center ma-2 mr-0"},[_c(VIcon,{attrs:{"left":"","color":_vm.tableConfig.color}},[_vm._v(_vm._s(_vm.tableConfig.icon))]),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.tableConfig.title))]),_c(VSpacer),_c(VTextField,{staticClass:"search-input mx-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","rounded":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('EntityDialog',{attrs:{"item":_vm.item},on:{"update:config":function($event){return _vm.updateConfig($event)}}})],1),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.tableConfig.filter.headers,"items":_vm.tableConfig.values,"items-per-page":5,"search":_vm.search,"height":"100%"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }