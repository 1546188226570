import { mapGetters } from "vuex"
import _ from "lodash"

export const MapWidgetMixin = {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isValid: this.item.valid,
            cardConfig: {
                title: this.item.props.title,
                icon: this.item.props.icon,
                color: this.item.props.color,
                values: this.item.props.values,
                filter: this.item.props.filter,
                format: this.item.props.format
            },
            layers: [
                { id: "l_locations_clusters", checked: true },
                { id: "l_locations_clusters_count", checked: true },
                { id: "l_locations_names", checked: true },

                { id: "l_locations_center_points", checked: false },
                { id: "l_locations_center_points_icon", checked: true },
                { id: "l_locations_clusters_points", checked: false },
                { id: "l_locations_clusters_points_icon", checked: true },
                { id: "l_locations_up_zoom", checked: true },
                { id: "l_locations_down_zoom", checked: false },

                { id: "l_storages_clusters", checked: true },
                { id: "l_storages_clusters_count", checked: true },
                { id: "l_storages_names", checked: true },
                { id: "l_storages_up_zoom", checked: true },
                { id: "l_storages_down_zoom", checked: false },
                { id: "l_storages_center_points", checked: false },
                { id: "l_storages_center_points_icon", checked: true },
                { id: "l_storages_clusters_points", checked: false },
                { id: "l_storages_clusters_points_icon", checked: true },
            ]
        };
    },
    created() {
        this.$set(this.cardConfig, 'id', this.item.props.id)
        this.$emit("check:validations", this.isValid)
        this.updateLayers();
    },
    computed: {
        ...mapGetters({
            storages: 'authority/authoritiesStoragesDevices',
            locationsList: 'authority/authoritiesLocations',
            storageDeviceTypesByCompany: 'storage_type/storagesTypesByCompany',
        }),
        mapConfig: {
            get() {
                return this.$store.getters["general/mapConfig"]
            },
            set(val) {
                this.$store.commit("general/SET_MAP_CONFIG", val)
            },
        },
        isEditingEnabled() {
            return this.$store.getters['dashboard/IS_EDITING_MODE'];
        }
    },
    methods: {
        updateLayers() {
            this.loading = true;
            if (Object.keys(this.cardConfig.filter).includes('nestedValues') && this.cardConfig.filter.nestedValues.length > 0) {
                // console.log(this.cardConfig.filter.nestedValues)
                // const layers = {...this.layers}
                // let newLayers = [];
                // _.forEach(this.cardConfig.filter.nestedValues, (l)=>{
                //   const layer = _.find(layers, {id: l})
                //
                //   if (layer) {
                //     newLayers.push({...layer, checked: true})
                //   } else {
                //     newLayers.push({...layer, checked: false})
                //   }
                // })
                _.forEach(this.layers, (l, i) => {
                    l.checked = this.cardConfig.filter.nestedValues.includes(l.id)
                })
            }
            this.loading = false;
        },
        checkValidation(e) {
            console.log(e)
            this.isValid = e;
            this.$emit('check:validations', e)
        },
        updateConfig(newConfig) {
            console.log(newConfig)
            this.$set(this, 'cardConfig', {
                ...newConfig,
                id: this.item.props.id || newConfig.id,
                title: newConfig.title,
                icon: newConfig.icon,
                color: newConfig.color,
                filter: {...newConfig.filter},
                format: {...newConfig.format}
            })

            this.$emit('update:card', this.cardConfig);
        }
    }
};
