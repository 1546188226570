import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isEditingEnabled),expression:"isEditingEnabled"}],attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline d-flex justify-space-between align-center mb-4"},[_c('h3',[_vm._v("Chart Config")]),_c('icon-picker',{attrs:{"color":_vm.config.color,"current":_vm.config.icon.slice(4, _vm.config.icon.length)},model:{value:(_vm.config.icon),callback:function ($$v) {_vm.$set(_vm.config, "icon", $$v)},expression:"config.icon"}})],1),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","color":"secondary","label":"Title","rules":_vm.rules.title},model:{value:(_vm.config.title),callback:function ($$v) {_vm.$set(_vm.config, "title", $$v)},expression:"config.title"}}),_c(VAutocomplete,{attrs:{"outlined":"","dense":"","color":"secondary","item-color":"secondary","items":_vm.entities,"label":"Entity"},model:{value:(_vm.config.filter.entity),callback:function ($$v) {_vm.$set(_vm.config.filter, "entity", $$v)},expression:"config.filter.entity"}}),_c('color-picker',{attrs:{"current":_vm.config.color},model:{value:(_vm.config.color),callback:function ($$v) {_vm.$set(_vm.config, "color", $$v)},expression:"config.color"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveConfig}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }