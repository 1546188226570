import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"flex-column",attrs:{"justify":"start","align":"start"}},[_c('span',{staticClass:"mx-4"},[_vm._v(" "+_vm._s(_vm.$t("widgets.dialogs.fields.color"))+" ")]),_c(VCol,{staticStyle:{"min-width":"220px"}},[_c(VTextField,{staticClass:"ma-0 pa-0 elevation-0",attrs:{"hide-details":"","solo":"","persistent-hint":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VMenu,{staticClass:"elevation-0",attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({style:(_vm.swatchStyle)},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":"","mode.sync":"rgb"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }