<template>
  <v-dialog v-model="dialog" persistent max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on" v-show="isEditingEnabled">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline d-flex justify-space-between align-center mb-4">
        <h3>Card Config</h3>
        <icon-picker :color="config.color" :current="config.icon.slice(4, config.icon.length)" v-model="config.icon" />
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field outlined dense color="secondary" v-model="config.title" label="Title" :rules="rules.title"></v-text-field>
          <!-- Agregar nuevos campos para Process, Group y Structure -->
          <v-autocomplete outlined dense color="secondary" item-color="secondary"  v-model="config.filter.processIds" :items="processes" label="Process"></v-autocomplete>
          <v-select outlined dense color="secondary" item-color="secondary"  v-model="config.filter.groupIds" :items="groups" label="Group"></v-select>
          <v-autocomplete outlined dense color="secondary" item-color="secondary"  v-model="config.filter.nestedValue" :items="structures" label="Nested values"></v-autocomplete>
          <color-picker :current="config.color" v-model="config.color" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Cancel</v-btn>
        <v-btn text @click="saveConfig">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ColorPicker from "@dashboard/components/common/ColorPicker.vue";
import IconPicker from "@dashboard/components/common/IconPicker.vue";

export default {
  name: 'ProductDialog',
  components: {IconPicker, ColorPicker},
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: 'PRODUCT_PIE',
        icon: 'mdi-chart-pie',
        color: '#232323',
        filter: {
          processIds: null,
          groupIds: null,
          nestedValue: null
        },
        subtype: 'PRODUCT_PIE',
        type: 'CHART'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: 'PRODUCT_PIE',
        icon: 'mdi-chart-pie',
        color: '#232323',
        filter: {
          processIds: null,
          groupIds: null,
          nestedValue: null
        },
        subtype: 'PRODUCT_PIE',
        type: 'CHART'
      },
      icons: ['mdi-cog', 'mdi-card-bulleted', 'mdi-carrot', 'mdi-leaf'],
      // Estas listas pueden ser actualizadas con valores reales
      processes: ['Process A', 'Process B', 'Process C'],
      groups: ['Group A', 'Group B', 'Group C'],
      structures: ['Structure A', 'Structure B', 'Structure C'],
      units: ['Unit A', 'Unit B', 'Unit C'],
    };
  },
  created() {
    this.config = {
      ...this.item.props,
      subtype: "PRODUCT_PIE",
      icon: "mdi-chart-pie",
      info: "PRODUCT_PIE",
    };
  },
  computed: {
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    saveConfig() {
      if (this.$refs.form.validate()) {
        this.$emit('update:config', {
          ...this.config,
          color: this.config.color.slice(0, 7)
        });
        this.dialog = false;
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #424242;
}
</style>
