import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c(VRow,{staticClass:"ma-0 pa-0 d-flex justify-space-between"},[_c(VCol,{staticClass:"ma-0 pa-2",attrs:{"align-self":"start","cols":"4","justify":"start"}},[(!_vm.isEditingEnabled)?_c(VTextField,{attrs:{"clearable":false,"label":_vm.$t('widgets.search'),"append-icon":"mdi-magnify","background-color":"#EAEAEA80","color":"secondary","dense":"","hide-details":"","outlined":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fetchByText()},"click:clear":function($event){return _vm.fetchData()},"click:append":function($event){return _vm.fetchByText()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1),_c(VCard,{staticClass:"ma-0 pa-0 fill-height overflow-hidden d-flex justify-space-between full-width",attrs:{"elevation":"0"}},[_c('LoadingWidgetData',{attrs:{"loading":_vm.loading}}),_c('EmptyWidgetData',{attrs:{"is-empty":!_vm.isPresent && !_vm.loading}}),_c(VDataTable,{staticClass:"elevation-0 overflow-y-auto full-width",attrs:{"footer-props":{
          ..._vm.rowsPerPageItemsOptions,
          itemsPerPageText: _vm.$t('general.table.itemsPerPageText'),
          pageText: '{0}-{1} ' + _vm.$t('general.table.pageText') + ' {2}',
        },"headers":_vm.localHeaders,"items":_vm.values,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"loading-text":_vm.$t('general.notification.loadData'),"no-data-text":_vm.$t('general.ui.not_data_found'),"no-results-text":_vm.$t('general.ui.not_search_found'),"options":_vm.options,"server-items-length":_vm.totalFromServer,"sort-by":this.defaultSort,"sort-desc":(_vm.orderBy === 'DESC' || (_vm.orderBy && _vm.orderBy.key === 'DESC'))},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){return _vm.$set(this, "defaultSort", $event)},"update:sort-by":function($event){return _vm.$set(this, "defaultSort", $event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }