<template>
  <div v-if="!loading">
    <span v-if="isPresent">
      <v-img contain :height="height - 32" :src="value" class="ma-0 pa-0" style="border-radius: .2em" />
    </span>
    <div v-else class="d-flex fill-height justify-center align-center">
      <v-chip disabled small>
        <v-icon small>mdi-alert-outline</v-icon>
        <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('general.ui.not_data_found') }}</p>
      </v-chip>
    </div>
  </div>
  <div v-else class="d-flex justify-start align-center fill-height">
    <v-chip small disabled>
      <v-progress-circular indeterminate v-if="loading" color="grey darken-1" size="14" width="3" />
      <p style="font-size: 12px; font-weight: normal" class="ma-0 pa-0 mx-4">{{ $t('widgets.loadData') }}</p>
    </v-chip>
  </div>
</template>

<script>
import LanguageService from "@/services/LanguajeService";
import NumberFormatService from "@/services/NumberFormatService";
import { FilterWidgetMixin } from "@/module/dashboard/mixins/filter-widget-mixin";

export default {
  name: "ImageRender",
  mixins: [FilterWidgetMixin],
  props: {
    initialized: {
      type: Boolean,
      default: false
    },
    tColor: {
      type: String,
      default: '#232323'
    },
    widgetId: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      isPresent: false,
      value: null,
      loading: false
    };
  },
  // async created() {
  //   await this.fetchData(true);
  // },
  mounted() {
    this.$root.$on('apply:dashboard-filters', (checkOnCreate = false) => {
      this.fetchData(checkOnCreate);
    })
  },
  computed: {
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    async fetchData(checkOnCreated) {
      if (!this.isEditingEnabled) {
        if (checkOnCreated || this.initialized) {
          this.loading = true;
          await this.$store.dispatch('dashboard/fetchWidgetConfig', [this.widgetId, {}])
            .then(res => {
              if (res.reload) {
                this.$root.$emit('reload:layout', { reload: true, layoutId: this.layout.id });
              }

              this.value = res.value;

              this.$emit('update:value', this.value);
              this.isPresent = true;
            })
            .catch(err => {
              console.log(err)
              this.isPresent = false;
            })
            .finally(() =>  this.loading = false)
        } else {
          this.loading = false;
        }
      } else {
        this.isPresent = false
        this.loading = false
      }
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
  },

  beforeDestroy() {
    this.$root.$off('apply:dashboard-filters')
    this.$root.$off('on:recalculate')
  }
}
</script>

<style scoped>
.v-chip {
  border-radius: .3em;
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.image-container .image-content {
  max-width: 100%;
  object-fit: contain !important;
}
</style>