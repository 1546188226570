import i18n from "@/plugins/i18n"

export const ChartWidgetMixin = {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            ready: false,
            isValid: this.item.valid,
            chartConfig: {
                title: this.item.props.title,
                icon: this.item.props.icon,
                color: this.item.props.color,
                itemsLimit: this.item.props.itemsLimit,
                values: this.item.props.values,
                filter: this.item.props.filter,
                format: this.item.props.format
            },
        };
    },
    created() {
        this.$set(this.chartConfig, 'id', this.item.props.id)
        this.$emit("check:validations", this.isValid)
    },
    computed: {
        isEditingEnabled() {
            return this.$store.getters['dashboard/IS_EDITING_MODE'];
        }
    },
    methods: {
        getTranslation(path, value) {
            if (!path.includes('YEAR'))
                return i18n.t(`widgets.chart.${path}`);
            return value;
        },
        checkValidation(e) {
            this.isValid = e;
            this.$emit('check:validations', e)
        },
        exportChart(fileName) {
            this.$refs.renderValues.export(fileName);
        },
        updateConfig(newConfig) {
            this.$set(this, 'chartConfig', {
                ...newConfig,
                id: this.item.props.id || newConfig.id,
                title: newConfig.title,
                icon: newConfig.icon,
                color: newConfig.color,
                itemsLimit: newConfig.itemsLimit,
                filter: {...newConfig.filter},
                format: {...newConfig.format}
            })

            this.$set(this.chartOptions, 'colors', [`${this.chartConfig.color}`])
            //this.$refs.chart.refresh();

            this.$emit('update:card', this.chartConfig);
        }
    }
};
