import NumberFormatService from "@/services/NumberFormatService";

export const ColumnMixin = {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              zoom: false,
              download: true
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            }
          },
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint || 2, this.format.region || 'en-us')
            }
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            distributed: true,
            columnWidth: '40%'
          }
        },
        theme: {
          monochrome: {
            enabled: true,
            color: this.item.props.color,
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        legend: {
          show: false
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint || 2, this.format.region || 'en-us')
            }
          }
        },
      },
      chartSeries: [{
        name: '',
        data: this.item.props.values.yAxis || []
      }],
      format: {
        afterPoint: null,
        region: null,
      },
    };
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    updateChart(e) {
      this.chartOptions.xaxis.categories = e.xAxis;
      this.chartSeries[0].data = e.yAxis;

      this.$set(this, 'format', e.format);

      this.ready = true;
      this.$refs.renderValues.refreshChart();
    },
  },
};
