<template>
  <v-dialog v-model="dialog" persistent max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on" v-show="isEditingEnabled">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline d-flex justify-space-between align-center mb-4">
        <h3>Chart Config</h3>
        <icon-picker :color="config.color" :current="config.icon.slice(4, config.icon.length)" v-model="config.icon" />
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field outlined dense color="secondary" v-model="config.title" label="Title" :rules="rules.title"></v-text-field>
          <v-autocomplete outlined dense color="secondary" item-color="secondary" v-model="config.filter.entity" :items="entities" label="Entity"></v-autocomplete>
          <color-picker :current="config.color" v-model="config.color" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="saveConfig">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ColorPicker from "@dashboard/components/common/ColorPicker.vue";
import IconPicker from "@dashboard/components/common/IconPicker.vue";

export default {
  name: 'EntityDialog',
  components: {IconPicker, ColorPicker},
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: 'ENTITY_BAR',
        icon: 'mdi-chart-box-outline',
        color: '#232323',
        filter: {
          entity: null
        },
        subtype: 'ENTITY_BAR',
        type: 'CHART'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: 'ENTITY_BAR',
        icon: 'mdi-chart-box-outline',
        color: '#232323',
        filter: {
          entity: null
        },
        subtype: 'ENTITY_BAR',
        type: 'CHART'
      },
      entities: [
        'Record', 'Document', 'Location', 'StorageDevice', 'Culture',
        'Season', 'Input', 'Operator', 'Equipment', 'Survey'
      ],
      defaultConfig: null
    };
  },
  created() {
    this.config = {
      ...this.item.props,
      subtype: "ENTITY_BAR",
      icon: "mdi-chart-bar",
      info: "ENTITY_BAR",
    };
  },
  computed: {
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    saveConfig() {
      if (this.$refs.form.validate()) {
        this.$emit('update:config', {
          ...this.config,
          color: this.config.color.slice(0, 7)
        });
        this.dialog = false;
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.headline {
  color: #424242;
}

:deep(.v-input.v-color-picker__alpha) {
  display: none !important;
}

:deep(.v-color-picker__preview:not(.v-color-picker__preview--hide-alpha) .v-color-picker__hue) {
  margin-bottom: 0 !important;
}
</style>
