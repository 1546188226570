import NumberFormatService from "@/services/NumberFormatService";

export const DonutMixin = {
  data() {
    return {
      chartOptions: {
        chart: {
          series: [],
          type: 'donut',
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              zoom: false,
              download: true
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            }
          },
        },
        labels: [],
        theme: {
          monochrome: {
            color: `${this.item.props.color}`,
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          position: 'bottom',
          onItemClick: {
            toggleDataSeries: true
          },
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter: (val) => {
              return NumberFormatService.formatNumber(val, this.format.afterPoint, this.format.region)
            }
          }
        },
      },
      chartSeries: [{
        name: this.item.props.title,
        data: this.item.props.values.yAxis || []
      }],
      format: {
        afterPoint: null,
        region: null,
      },
    };
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    updateChart(e) {
      // this.chartOptions.chart.toolbar.show = true;
      this.chartOptions.labels = e.xAxis;
      this.chartSeries = e.yAxis;

      this.$set(this, 'format', e.format);

      this.ready = true;
      this.$refs.renderValues.refreshChart();
    }
  },
};
