import LanguajeService from "@/services/LanguajeService";
import _ from "lodash";

export const CommonWidgetMixin = {
        mounted() {
            // if (this.item.props.filter && Array.isArray(this.item.props.filter.processIds) && this.item.props.filter.processIds.length > 0) {
            //     this.$set(this.loading, 'process', true);
            //     // Espera a que todas las promesas se resuelvan
            //     Promise.all([
            //         this.fetchProcessByVersionList([this.item.props.filter.processIds, this.$toast])
            //     ]).then((results) => {
            //         // Aplanar los resultados y eliminar duplicados, si es necesario
            //         const data = _.uniqBy(_.flatten(results), 'id');
            //
            //         // Actualiza widgetProcess con los procesos fusionados
            //         this.widgetProcess = this.mergeProcesses([...this.process], data);
            //
            //         this.$set(this.loading, 'process', false);
            //     });
            // } else {
            //     this.widgetProcess = [...this.process];
            // }

            this.widgetProcess = [...this.process];
        },

    methods: {
        clearData() {
            this.$set(this, 'processSelected', []);
            this.$set(this, 'groupSelected', []);
            this.$set(this, 'structureSelected', []);
            this.$set(this, 'groups', []);
            this.$set(this, 'structures', []);
            this.$set(this, 'units', []);
            this.$set(this, 'nestedValues', []);
            this.$set(this, 'dataToCheck', []);
        },
        async onSelectedProcess() {
            this.loadingFields = true;
            this.clearData();
            await this.getGroups()
            this.loadingFields = false;
        },

        mergeProcesses(processList, processVersionsList) {
            const merged = [];

            // Añade los elementos de processList a merged con su nombre traducido
            processList.forEach(process => {
                merged.push({
                    ...process,
                    proccess_name: this.translate(process.language_key)
                });
            });

            // Revisa cada elemento en processVersionsList y añade "Current" si es necesario
            processVersionsList.forEach(pv => {
                const foundInMerged = merged.find(process => process.id === pv.id);
                if (!foundInMerged) {
                    merged.push({
                        ...pv,
                        proccess_name: `${this.translate(pv.language_key)} (V${pv.version})`,
                    });
                }
            });

            // Actualiza this.process con la nueva lista fusionada
            return merged;
        },
        translate(key, uppercase) {
            return LanguajeService.getKey3(key)
        },
        getValue(input, property) {
            if (typeof input === 'object' && input !== null && property in input) {
                return input[property];
            } else {
                return input;
            }
        },
        async toggleSelection(autocompleteName, types) {
            let allItems = [];
            let selectedItems = [];

            switch (autocompleteName) {
                case 'widgetProcess':
                    allItems = this.widgetProcess;
                    selectedItems = this.config.filter.processIds;
                    break;
                case 'nestedValues':
                    allItems = this.nestedValues;
                    selectedItems = this.dataToCheck;
                    break;
                case 'groups':
                    allItems = this.groups;
                    selectedItems = this.groupSelected;
                    break;
                case 'structures':
                    allItems = this.structures;
                    selectedItems = this.structureSelected;
                    break;
                default:
                    return;
            }

            if (selectedItems.length < allItems.length) {
                const newSelection = allItems.map(item => item.id || item.value || item);
                if (autocompleteName === 'widgetProcess') {
                    this.$set(this.config.filter, 'processIds', newSelection);
                    await this.onSelectedProcess();
                } else if (autocompleteName === 'nestedValues') {
                    this.$set(this, 'dataToCheck', this.nestedValues.filter(item => newSelection.includes(item.id)));
                } else if (autocompleteName === 'groups') {
                    this.$set(this, 'groupSelected', this.groups.filter(item => newSelection.includes(item.id)));
                    await this.getStructuresAndValues(types);
                } else if (autocompleteName === 'structures') {
                    this.$set(this, 'structureSelected', this.structures.filter(item => newSelection.includes(item.id)));
                    await this.getStructuresAndValues(types);
                }
            } else {
                if (autocompleteName === 'widgetProcess') {
                    this.config.filter.processIds = [];
                    this.clearData();
                } else if (autocompleteName === 'nestedValues') {
                    this.dataToCheck = [];
                } else if (autocompleteName === 'groups') {
                    this.groupSelected = [];
                } else if (autocompleteName === 'structures') {
                    this.structureSelected = [];
                }
            }
        },

        computeSelectionIcon(autocompleteName) {
            let allItems = [];
            let selectedItems = [];

            switch (autocompleteName) {
                case 'widgetProcess':
                    allItems = this.widgetProcess;
                    selectedItems = this.config.filter.processIds;
                    break;
                case 'nestedValues':
                    allItems = this.nestedValues;
                    selectedItems = this.dataToCheck;
                    break;
                case 'groups':
                    allItems = this.groups;
                    selectedItems = this.groupSelected;
                    break;
                case 'structures':
                    allItems = this.structures;
                    selectedItems = this.structureSelected;
                    break;
                default:
                    return 'mdi-checkbox-blank-outline';
            }

            if (selectedItems.length === 0) {
                return 'mdi-checkbox-blank-outline';
            } else if (selectedItems.length === allItems.length) {
                return 'mdi-checkbox-marked';
            } else {
                return 'mdi-minus-box';
            }
        },
    },
};
