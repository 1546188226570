<template>
  <ChartWrapper
    :chartConfig="chartConfig"
    :isValid="isValid"
    :isEditingEnabled="isEditingEnabled"
    :loading="loading"
    :ready="ready"
    @on:download="exportChart(chartConfig.title)"
    @reload:requested="reloadData"
    :item="item"
    :link="false"
  >
    <template #dialog>
      <QrTopCitiesDialog @update:validation="checkValidation($event)" :item="item" @update:config="updateConfig($event)" />
    </template>

    <template #values>
      <RenderValues
        @update:values="updateChart($event)"
        @on:ready="ready = $event"
        ref="renderValues"
        :chart-options="chartOptions"
        :chart-series="chartSeries"
        :ready="ready"
        :widget-id="item.props.id"
        :t-color="chartConfig.color"
        :initialized="item.initialized"
        :applicable-filters="item.applicableFilters"
      />
    </template>
  </ChartWrapper>
</template>

<script>
import ChartWrapper from "@/module/dashboard/components/widgets/chart/common/ChartWrapper.vue"
import QrTopCitiesDialog from "@/module/dashboard/components/widgets/chart/bar/qr_top_cities/QrTopCitiesDialog.vue";
import RenderValues from "@/module/dashboard/components/widgets/chart/common/RenderValues.vue";
import { ChartWidgetMixin } from "@/module/dashboard/mixins/chart-widget.mixin";
import { ReloadWidgetMixin } from "@/module/dashboard/mixins/reload-widget.mixin";
import { BarMixin } from "@/module/dashboard/components/widgets/chart/bar/mixins/bar.mixin"
import ShowFilteredData from "@/module/dashboard/components/common/ShowFilteredData.vue";

export default {
  components: {
    ShowFilteredData,
    ChartWrapper,
    QrTopCitiesDialog,
    RenderValues
  },
  mixins: [ChartWidgetMixin, ReloadWidgetMixin, BarMixin],
}
</script>
