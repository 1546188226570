<template>
  <v-layout style="background-color: #f6f5f5;" class="pa-0" fill-height>
    <!--  NOTA: mb-12 es usado a causa de tener que añadir top al toolbar superior de opciones  -->
    <v-container fluid :class="{ 'mb-12': isEditingEnabled }">
      <v-row>
        <v-col>
          <div id="content" v-if="layout">
            <grid-layout class="grid"
                         v-if="isEditingEnabled"
                         :layout="layout.widgets"
                         v-bind="gridLayoutConfig"
                         :key="updatedKey"
            >
              <grid-item v-for="item in layout.widgets" :key="`editing-${item.i}`"
                         :x="item.x"
                         :y="item.y"
                         :w="item.w"
                         :h="item.h"
                         :i="item.i"
                         @container-resized="onResized"
                         :is-resizable="item.isResizable">
                <component :is="getComponent(item.type, item.subtype)" v-bind="{item}" @update:card="setConfig($event)" @check:validations="$set(formValidations, item.i, $event)"></component>
                <span class="remove" @click="removeItem(item.i)"><v-icon small color="black">mdi-close</v-icon></span>
              </grid-item>
            </grid-layout>

            <!-- Grid para modo de visualización (con .sync) -->
            <grid-layout class="grid"
                         v-else
                         :layout.sync="layout.widgets"
                         v-bind="gridLayoutConfig"
                         :key="updatedKey"
            >
              <grid-item v-for="(item, index) in layout.widgets" :key="`viewing-${item.i}`"
                         :x="item.x"
                         :y="item.y"
                         :w="item.w"
                         :h="item.h"
                         :i="item.i"
                         @container-resized="onResized"
              >
                <component :is="getComponent(item.type, item.subtype)" v-bind="{item}" :key="`component-${index}`"></component>
              </grid-item>
            </grid-layout>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-app-bar app bottom elevation="0" class="px-6" color="white" v-if="isEditingEnabled">
      <v-spacer></v-spacer>
      <v-btn :disabled="isNotValidCards || !(layout.widgets.length > 0)" outlined class="mx-1" elevation="0" color="secondary" @click="save()" :loading="loading.save">{{$t('general.buttons.save')}}</v-btn>
      <v-btn class="mx-1" outlined elevation="0" color="grey" @click="cancel()" :disabled="loading.save">{{$t('general.buttons.cancel')}}</v-btn>
    </v-app-bar>
  </v-layout>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import {mapGetters} from "vuex";
import { GridLayout, GridItem } from 'vue-grid-layout';
import CountCard from '@dashboard/components/widgets/card/count/CountCard.vue';
import ImageCard from '@dashboard/components/widgets/card/image/ImageCard.vue';
import AverageCard from '@dashboard/components/widgets/card/average/AverageCard.vue';
import SumByUnitCard from "@dashboard/components/widgets/card/sum_by_unit/SumCard.vue";
import SumCard from "@dashboard/components/widgets/card/sum/SumCard.vue";
import DocumentExpiryCard from "@dashboard/components/widgets/statics/documents/DocumentCard.vue";
import EntityTable from "@dashboard/components/widgets/table/entity/EntityTable.vue";
import QrTopVisitTable from "@dashboard/components/widgets/table/qr_top_visit/QrTopVisitTable.vue";
import EntityBarChart from "@dashboard/components/widgets/chart/bar/entity/EntityChart.vue";
import LocationBarChart from "@dashboard/components/widgets/chart/bar/location/LocationChart.vue";
import QrTopCitiesBarChart from "@dashboard/components/widgets/chart/bar/qr_top_cities/QrTopCitiesChart.vue";
import ProductPieChart from "@dashboard/components/widgets/chart/pie/product/ProductChart.vue";
import ProductDonutChart from "@dashboard/components/widgets/chart/donut/product/ProductChart.vue";
import TopRecordTable from "@dashboard/components/widgets/statics/records/RecordTable.vue";
import MapCard from "@dashboard/components/widgets/statics/map/MapCard.vue";
import MapLocationCard from "@dashboard/components/widgets/map/locations/LocationCard.vue";
import QrLastVisitChart from "@dashboard/components/widgets/chart/dots/qr_last_visit/QrLastVisitChart.vue";
import QrVisitCitiesTable from "@dashboard/components/widgets/table/qr_visit_cities/QrVisitCitiesTable.vue";
import ProducersByStateTable
  from "@/module/dashboard/components/widgets/table/producers_by_state/ProducersByStateTable.vue"
import ProductsByStateTable
  from "@/module/dashboard/components/widgets/table/products_by_state/ProductsByStateTable.vue"
import ProductsByStateChart
  from "@/module/dashboard/components/widgets/chart/column/products_by_state/ProductsByStateChart.vue"
import ProductsByLocationChart
  from "@/module/dashboard/components/widgets/chart/bar/products_by_location/ProductsByLocationChart.vue"
import LocationsByStateTable
  from "@/module/dashboard/components/widgets/table/locations_by_state/LocationsByStateTable.vue"
import BonusEstimateTable from "@/module/dashboard/components/widgets/table/estimate/BonusEstimateTable.vue"

let mouseXY = {"x": null, "y": null};
let DragPos = {"x": null, "y": null, "w": null, "h": null, "i": null};

export default {
  components: {
    GridLayout,
    GridItem,
    CountCard,
    ImageCard,
    AverageCard,
    SumByUnitCard,
    SumCard,
    EntityBarChart,
    LocationBarChart,
    ProductPieChart,
    ProductDonutChart,
    DocumentExpiryCard,
    TopRecordTable,
    QrTopVisitTable,
    QrTopCitiesBarChart,
    QrVisitCitiesTable,
    ProductsByStateTable,
    ProductsByStateChart,
    ProductsByLocationChart,
    BonusEstimateTable
  },
  data() {
    return {
      loading: {
        save: false
      },
      updatedKey: 0,
      formValidations: {},
      isNotValidCards: false,
      originalLayoutName: '',
    };
  },
  watch: {
    formValidations: {
      handler() {
        this.isNotValidCards = _.filter(this.formValidations, (v) => {
          return v === false
        }).length > 0
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      generalFormat: 'dashboard/GENERAL_FORMAT'
    }),
    layout: {
      get() {
        return this.$store.getters['dashboard/LAYOUT'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_LAYOUT', val);
      }
    },
    layouts: {
      get() {
        return this.$store.getters['dashboard/LAYOUTS'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_LAYOUTS', val);
      }
    },
    gridLayoutConfig() {
      let config = {
        class: [{ 'editing-mode': this.isEditingEnabled }, { 'full-height': this.layout.widgets.length === 0 }],
        ref: "gridlayout",
        colNum: 12,
        rowHeight: 30,
        margin: [10, 10],
        verticalCompact: true,
        useCssTransforms: true,
        responsive: true,
        isBounded: true,
      };

      if (this.isEditingEnabled) {
        return {
          ...config,
          isDraggable: true,
          isResizable: true,
        };
      } else {
        return {
          ...config,
          isDraggable: false,
          isResizable: false,
        };
      }
    },
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    },
    isSaved() {
      return !(_.filter(this.layouts, { isSaved: false }).length > 0)
    }
  },

  created() {
    console.log('layout: ', this.layout)
  },

  mounted() {
    setTimeout(() => {
      console.log('event: on:load-filters')
      this.$root.$emit('on:load-filters')
      this.$root.$emit('on:recalculate');
      this.$root.$emit('apply:dashboard-filters', true);
    }, 0)

    document.addEventListener("dragover", function (e) {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);
    this.$root.$on('dragend-item', this.dragend);
    this.$root.$on('drag-item', this.drag);
  },

  methods: {
    onResized(i, newH, newW, newHPx, newWPx){
      _.filter(this.layout.widgets, (w) => {
        if(w.i === i) {
          w.height = newHPx;
          w.weight = newWPx;
        }
      })
    },
    async save() {
      this.loading.save = true;
      const transformedData = this.transformWidgetsToDashboardModel(this.layout.widgets);
      const toSend = _.pick(transformedData, ['widgets', 'id', 'name', 'selected', 'format'])

      if (this.generalFormat.updated) {
        toSend.format = {...this.generalFormat};
      }

      await this.$store.dispatch('dashboard/saveDashboard', toSend).finally(async () => {
        await this.reloadDashboard();
        this.loading.save = false;
        this.updatedKey += 1;
      });
    },
    async reloadDashboard() {
      await this.$root.$emit('reload:layout', { reload: true, layoutId: this.layout.id });

      // await this.$store.dispatch('dashboard/fetchUserLayouts').finally(() => {
      //   const currentLayout = this.layouts.find(layout => layout.name === this.layout.name);
      //
      //   if (currentLayout) {
      //     currentLayout.current = true
      //     this.$store.commit('dashboard/SET_LAYOUT', currentLayout);
      //     this.$store.commit('dashboard/SET_WIDGETS', currentLayout.widgets);
      //   } else {
      //     const defaultLayout = this.layouts.find(layout => layout.selected);
      //     if (defaultLayout) {
      //       defaultLayout.current = true;
      //       this.$store.commit("dashboard/SET_LAYOUT", defaultLayout)
      //       this.$store.commit("dashboard/SET_WIDGETS", defaultLayout.widgets)
      //     }
      //   }
      //
      //   this.$store.commit('dashboard/SET_WIDGET_DRAWER', false)
      //   this.$store.commit('dashboard/SET_EDITING_MODE', false)
      // });
      this.$store.commit('dashboard/SET_WIDGET_DRAWER', false)
      this.$store.commit('dashboard/SET_EDITING_MODE', false)
    },
    async cancel() {
      // TODO: check if there are unsaved changes (it's not more necessary)
      // if (this.layout.id) {
      //   if (!(this.layout.widgets.length > 0) && !this.isNotValidCards) {
      //     const layout = this.layouts.find(layout => layout.selected === true);
      //     if (layout) {
      //       this.layout = { ...layout }
      //     } else {
      //       this.layout = null
      //     }
      //   } else {
      //     const layout = this.layouts.find(layout => layout.current === true);
      //     this.layout = { ...layout }
      //   }
      // } else {
      //   this.layouts = _.filter(this.layouts, layout => layout.id !== null);
      //   this.layout = null
      // }

      this.$emit('on:cancel');
      localStorage.removeItem('current:format');

      await this.reloadDashboard();
    },
    drag: function (item) {
      let parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true && (this.layout.widgets.findIndex(item => item.i === 'drop')) === -1) {
        this.layout.widgets.push({
          x: (this.layout.widgets.length * 2) % (this.colNum || 12),
          y: (this.layout.widgets.length * 2) % (this.colNum || 12),
          w: item.w,
          h: item.h,
          i: 'drop',
          isResizable: item.isResizable || true
        })
      }
      let index = this.layout.widgets.findIndex(item => item.i === 'drop');
      if (index !== -1) {
        try {
          this.$refs.gridlayout.$children[this.layout.widgets.length].$refs.item.style.display = "none";
        } catch {
        }
        let el = this.$refs.gridlayout.$children[index];
        el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
        let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

        if (mouseInGrid === true) {
          this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, item.h, item.w);
          DragPos.i = parseInt(String(index));
          DragPos.x = this.layout.widgets[index].x;
          DragPos.y = this.layout.widgets[index].y;
        }
        if (mouseInGrid === false) {
          this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, item.h, item.w);
          this.layout.widgets = this.layout.widgets.filter(obj => obj.i !== 'drop');
        }
      }
    },
    dragend(item) {
      let parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, item.h, item.w);
        this.layout.widgets = this.layout.widgets.filter(obj => obj.i !== 'drop');

        const id = uuidv4();
        const newItem = {
          ...item,
          x: DragPos.x,
          y: DragPos.y,
          w: item.w,
          h: item.h,
          i: id,
          isResizable: item.isResizable || true,
          props: {...item.props, id: id}
        };
        this.layout.widgets.push(newItem);
      }
    },
    removeItem: function(i) {
      const index = this.layout.widgets.map(item => item.i).indexOf(i);
      this.layout.widgets.splice(index, 1);
      this.$delete(this.formValidations, i);
    },
    getComponent(type, subtype = null) {
      switch (type) {
        case 'MAP':
          if (subtype === 'MAP_LOCATIONS') return MapLocationCard;
        case 'CARD':
          if (subtype === 'COUNT') return CountCard;
          if (subtype === 'IMAGE') return ImageCard;
          if (subtype === 'STRUCTURE_AVERAGE') return AverageCard;
          if (subtype === 'AGTRACE_SUM') return SumCard;
          if (subtype === 'AGTRACE_SUM_BY_UNIT') return SumByUnitCard;
          if (subtype === 'DOCUMENT_EXPIRY') return DocumentExpiryCard;
          if (subtype === 'MAP_BY_LAYERS') return MapCard;
          return null;
        case 'TABLE':
          if (subtype === 'ENTITY') return EntityTable;
          if (subtype === 'TOP_RECORDS') return TopRecordTable;
          if (subtype === 'TOP_TRACEABILITY_QR_VISIT') return QrTopVisitTable;
          if (subtype === 'CITY_QR_VISIT') return QrVisitCitiesTable;
          if (subtype === 'QUANTITY_PRODUCTS_BY_STATE_LOCATION') return ProductsByStateTable;
          if (subtype === 'PRODUCERS_BY_STATE_LOCATION') return ProducersByStateTable;
          if (subtype === 'LOCATIONS_BY_STATE') return LocationsByStateTable;
          if (subtype === 'ESTIMATE') return BonusEstimateTable;
          return null;
        case 'CHART':
          if (subtype === 'ENTITY_BAR') return EntityBarChart;
          if (subtype === 'COUNT_PROCESS_BY_LOCATION') return LocationBarChart;
          if (subtype === 'PRODUCT_PIE') return ProductPieChart;
          if (subtype === 'AGTRACE_COUNT_SELECTED_PRODUCTS') return ProductDonutChart;
          if (subtype === 'LAST_QR_VISIT_BY_RANGE') return QrLastVisitChart;
          if (subtype === 'TOP_CITY_QR_VISIT') return QrTopCitiesBarChart;
          if (subtype === 'QUANTITY_PRODUCTS_BY_STATE_LOCATION') return ProductsByStateChart;
          if (subtype === 'QUANTITY_PRODUCTS_BY_LOCATION') return ProductsByLocationChart;
          return null;
        default:
          return null;
      }
    },
    setConfig(e) {
      // Encuentra el índice del elemento que necesitas actualizar
      const index = this.layout.widgets.findIndex(item => item.props.id === e.id);

      console.log(e)
      console.log(index)
      // Si el elemento existe, actualiza su configuración
      if (index !== -1) {
        this.layout.widgets[index].props = { ...this.layout.widgets[index].props, ...e };
      }

      // Para forzar la reactividad en Vue, puedes necesitar establecer el array completo
      // Esto puede ser necesario si estás cambiando propiedades internas de un objeto
      this.layout.widgets = [...this.layout.widgets];
    },
    transformWidgetsToDashboardModel(widgets) {
      return {
        ...this.layout,
        widgets: widgets.map(widget => ({
          color: widget.props.color,
          title: widget.props.title,
          icon: widget.props.icon,
          type: widget.type,
          subType: widget.subtype,
          gridConfig: {
            i: widget.i,
            x: widget.x,
            y: widget.y,
            w: widget.w,
            h: widget.h,
            isResizable: widget.isResizable || true
          },
          filter: widget.props.filter,
          applicableFilters: widget.applicableFilters,
          format: widget.props.format,
          itemsLimit: widget.props.itemsLimit
        }))
      };
    }
  },
  beforeDestroy() {
    this.$root.$off('drag-item')
    this.$root.$off('dragend-item')
  }
}
</script>
