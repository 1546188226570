<template>
  <v-chip class="my-2 font-weight-bold full-width" :color="`#a9ffc6`" label small>
    <div>
      <v-icon left small :color="`#1c5d35`">
        {{ icon }}
      </v-icon>
      <span style="color: #1c5d35">
          <v-tooltip top content-class="title__tooltip">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ name | truncate(20) }}
              </span>
            </template>
            <span>{{ name }}</span>
          </v-tooltip>
        </span>
    </div>

    <div class="cursor-pointer">
      <v-tooltip top content-class="info__tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small :color="`#1c5d35`">
            mdi-information-slab-circle
          </v-icon>
        </template>
        <span>{{ info }}</span>
      </v-tooltip>
    </div>
  </v-chip>
</template>

<script>
  export default {
    props: ["name", "icon", "info"],
    data() {
      return {
        color: "#232323",
      }
    },
  }
</script>

<style scoped>
span :deep(.v-chip__content) {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.title__tooltip {
  width: auto;
  background-color: rgba(0, 0, 0) !important;
  color: white;
}

.info__tooltip {
  width: auto;
  max-width: 20%;
  min-width: 10%;
  background-color: rgba(0, 0, 0) !important;
  color: white;
}

</style>
