<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData()"
    @clear:form="clear($event)"
  >

    <template v-slot:custom-fields>
      <LoadingWrapper :loading="loadingFields">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              multiple
              :items="widgetProcess"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              v-model="config.filter.processIds"
              item-color="secondary"
              item-text="proccess_name"
              item-value="id"
              dense
              flat
              outlined
              color="secondary"
              :loading="loading.process"
              :rules="selectRule"
              @change="onSelectedProcess()"
              :menu-props="{ closeOnContentClick: false }"
            >
              <template v-slot:prepend-item>
                <v-list-item @click="() => toggleSelection('widgetProcess')">
                  <v-list-item-action>
                    <v-icon>{{ computeSelectionIcon('widgetProcess') }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{ config.filter.processIds.length === widgetProcess.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.process") }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.proccess_name | truncate(18) }}</span>
                </v-chip>
                <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                >(+{{ config.filter.processIds.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 200px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="secondary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.proccess_name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              multiple
              :items="groups"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.group')"
              v-model="groupSelected"
              item-color="secondary"
              item-text="name"
              return-object
              dense
              flat
              outlined
              color="secondary"
              :disabled="loading.process && groups.length === 0"
              :rules="selectRule"
              @change="getStructuresAndValues(types)"
            >
              <template v-slot:prepend-item>
                <v-list-item @click="() => toggleSelection('groups', types)">
                  <v-list-item-action>
                    <v-icon>{{ computeSelectionIcon('groups') }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{ groupSelected.length === groups.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.group") }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.name | truncate(5) }}</span>
                </v-chip>
                <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                >(+{{ groupSelected.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 200px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="secondary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              multiple
              :items="displayedStructures"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              @update:search-input="handleStructuresSearchChange($event)"
              v-model="structureSelected"
              item-color="secondary"
              item-text="value"
              return-object
              dense
              flat
              outlined
              color="secondary"
              :rules="selectRule"
              :disabled="loading.process"
            >
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.structure") }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:prepend-item>
                <v-list-item @click="() => toggleSelection('structures', types)">
                  <v-list-item-action>
                    <v-icon>{{ computeSelectionIcon('structures') }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{ structureSelected.length === groups.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 180px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="secondary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.value"
                        :max-chars="40"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:append-item>
                <AFooterPaginatorField
                  :items-length="filteredStructures.length"
                  :items-per-page="pagination.structures.itemsPerPage"
                  :itemPerPageText="false"
                  :current-page="pagination.structures.currentPage"
                  :valid="true"
                  @update:current-page="(e) => updatePagination('structures', { page: e.page, itemsPerPage: e.itemsPerPage })"
                  @update:items-per-page="(e) => updatePagination('structures', { page: e.page, itemsPerPage: e.itemsPerPage })"
                />
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.value | truncate(5) }}</span>
                </v-chip>
                <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                >(+{{ structureSelected.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                >
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </LoadingWrapper>
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from "@/module/dashboard/components/common/FormComponent.vue";
import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue";
import IconPicker from "@/module/dashboard/components/common/IconPicker.vue";
import { GlobalWidgetMixin } from "@/module/dashboard/mixins/global-widget.mixin";
import { CommonWidgetMixin } from "@/module/dashboard/mixins/common-widget.mixin";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin"
import i18n from "@/plugins/i18n";
import ReadMore from "@/components/ReadMoreComponent.vue";
import LoadingWrapper from "@/module/dashboard/components/common/LoadingWrapper.vue";
import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue";

export default {
  name: 'AverageDialog',
  components: {AFooterPaginatorField, LoadingWrapper, ReadMore, FormComponent, IconPicker, ColorPicker},
  mixins: [GlobalWidgetMixin, CommonWidgetMixin, FormWidgetMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.STRUCTURE_AVERAGE'),
        icon: 'mdi-card-bulleted',
        color: '#232323',
        filter: {
          processIds: [],
          groupIds: [],
          structureIds: []
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'STRUCTURE_AVERAGE',
        type: 'CARD'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.STRUCTURE_AVERAGE'),
        icon: 'mdi-card-bulleted',
        color: '#232323',
        filter: {
          processIds: [],
          groupIds: [],
          structureIds: []
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'STRUCTURE_AVERAGE',
        type: 'CARD'
      },
      defaultConfig: null,
      loadingFields: false
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.defaultConfig = { ...this.config };
    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "STRUCTURE_AVERAGE",
      icon: "mdi-card-bulleted",
      info: "STRUCTURE_AVERAGE",
    };

    // !TODO: Quitar despues
    // Asegurándonos de que todas las propiedades relevantes se inicialicen como arrays
    // this.config.filter.processIds = Array.isArray(this.config.filter.processIds) ? this.config.filter.processIds : [this.config.filter.processIds].filter(Boolean);
    // this.config.filter.groupIds = Array.isArray(this.config.filter.groupIds) ? this.config.filter.groupIds : [this.config.filter.groupIds].filter(Boolean);
    // this.config.filter.structureIds = Array.isArray(this.config.filter.structureIds) ? this.config.filter.structureIds : [this.config.filter.structureIds].filter(Boolean);
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          processIds: [...this.config.filter.processIds],
          groupIds: this.groupSelected.map(g => g.id),
          structureIds: this.structureSelected.map(s => s.id),
        },
        initialized: true
      };

      console.log(config)

      this.$emit('update:config', config);
      this.$emit('update:validation', this.valid);
      this.dialog = false;
    },
    async handleInitData() {
      if (this.item.initialized) {
        this.loadingFields = true;

        // Primero, asegúrate de que los procesos se carguen
        // await this.onSelectedProcess();

        await this.getGroups();
        this.groupSelected = this.groups.filter(g => this.config.filter.groupIds.includes(g.id));

        // Asegúrate de que los grupos estén seleccionados antes de proceder
        await this.$nextTick();

        await this.getStructuresAndValues(this.types);

        // Similarmente para las estructuras
        this.structureSelected = this.structures.filter(s => this.config.filter.structureIds.includes(s.id));

        this.loadingFields = false;
      } else {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = {...e}
      this.saveConfig(e)
    }
  }
};
</script>

<style scoped>
</style>
