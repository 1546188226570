<template>
  <div @dragend="dragend" @drag="drag" class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-chart">

      <ChipWidgetSubtype :name="$t('widgets.names.AGTRACE_COUNT_SELECTED_PRODUCTS')" icon="mdi-chart-timeline-variant" :info="$t('widgets.descriptions.AGTRACE_COUNT_SELECTED_PRODUCTS')" />

      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div class="skeleton-wrapper">
        <div class="skeleton-chart chart-align d-flex justify-center pa-2">
          <div class="skeleton-chart-container-grade">
            <div class="skeleton-donut"></div>
            <div class="skeleton-donut-hole"></div>
            <div class="skeleton-pie-section" v-for="i in 4" :key="i"></div>
          </div>
          <div class="skeleton-legend-container">
            <div class="skeleton-legend-item" v-for="i in 2" :key="`legend-${i}`"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";
import i18n from "@/plugins/i18n";


export default {
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      data: {
        type: 'CHART',
        subtype: 'AGTRACE_COUNT_SELECTED_PRODUCTS',
        props: {
          title: i18n.t('widgets.names.AGTRACE_COUNT_SELECTED_PRODUCTS'),
          values: {
            data: [20, 50, 10, 30],
            labels: ['Product 1', 'Product 2', 'Product 3', 'Product 4']
          },
          color: '#232323',
          icon: 'mdi-chart-arc',
          filter: {
            processIds: [],
            groupIds: [],
            nestedValues: [],
            unitShowed: null
          },
        },
        w: 5,
        h: 12,
        initialized: false,
        valid: false
      }
    };
  },
  computed: {
    borderColor() {
      return {
        borderLeft: `4px solid ${this.color}60`
      };
    },
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },

  },
  methods: {
    drag(e) {
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    },
  }
}
</script>

<style scoped>
@import "../../../../../styles/scss/components/_skeleton.scss";
@import "../../../../../styles/scss/components/skeletons/_chart.scss";
</style>
