import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ProcessService from "@dashboard/services/ProcessService";

export const GlobalWidgetMixin = {
    data() {
        return {
            selectableStructures: ['LIST', 'LISTSIMPLE', 'PRODUCT', 'EQUIPMENT', 'INPUT', 'SEASON', 'OPERATOR', 'MEASUREMENTUNIT', 'PRODUCTSIMPLE', 'EQUIPMENTSIMPLE', 'OPERATORSIMPLE', 'INPUTSIMPLE', 'SEASONSIMPLE', 'MEASUREMENTUNITSIMPLE'],
            dialog: false,
            valid: false,
            loading: {
                process: false,
            },
            widgetProcess: [],
            processSelected: [],
            groupSelected: [],
            structureSelected: [],
            dataToCheck: [],
            groups: [],
            structures: [],
            nestedValues: [],
            units: [],
            pagination: {
                nestedValues: {
                    currentPage: 1,
                    itemsPerPage: 10,
                },
                units: {
                    currentPage: 1,
                    itemsPerPage: 10,
                },
                structures: {
                    currentPage: 1,
                    itemsPerPage: 10,
                },
            },
            unitSearch: '',
            nestedValuesSearch: '',
            structuresSearch: '',
            types: { number: ['NUMBER'] }
        };
    },
    computed: {
        ...mapGetters({
            requireRules: 'general/requireRules',
            process: 'authority/authoritiesProcess',
        }),
        displayedNestedValues() {
            const start = (this.pagination.nestedValues.currentPage - 1) * this.pagination.nestedValues.itemsPerPage;
            const end = start + this.pagination.nestedValues.itemsPerPage;
            return this.filteredNestedValues.slice(start, end);
        },
        displayedUnits() {
            const start = (this.pagination.units.currentPage - 1) * this.pagination.units.itemsPerPage;
            const end = start + this.pagination.units.itemsPerPage;
            return this.filteredUnits.slice(start, end);
        },
        displayedStructures() {
            const start = (this.pagination.structures.currentPage - 1) * this.pagination.structures.itemsPerPage;
            const end = start + this.pagination.structures.itemsPerPage;
            if (!this.structures) this.structures = [];
            return this.structures.slice(start, end);
        },
        filteredUnits() {
            if (!this.unitSearch) {
                return this.units;
            }
            const lowerCaseSearch = this.unitSearch.toLowerCase();
            return this.units.filter(unit => unit.value.toLowerCase().includes(lowerCaseSearch));
        },
        filteredNestedValues() {
            if (!this.nestedValuesSearch) {
                return this.nestedValues;
            }
            const lowerCaseSearch = this.nestedValuesSearch.toLowerCase();
            return this.nestedValues.filter(nested => nested.value.toLowerCase().includes(lowerCaseSearch));
        },
        filteredStructures() {
            if (!this.structuresSearch) {
                return this.structures;
            }
            const lowerCaseSearch = this.structuresSearch.toLowerCase();
            return this.structures.filter(nested => nested.value.toLowerCase().includes(lowerCaseSearch));
        },
    },
    methods: {
        ...mapActions({
            fetchProcessByVersionList: "proccess/fetchProcessByVersionList",
        }),
        updatePagination(property, { page, itemsPerPage }) {
            this.pagination[property].currentPage = page;
            if (itemsPerPage) {
                this.pagination[property].itemsPerPage = itemsPerPage;
            }
        },
        handleNestedValuesSearchChange(searchTerm) {
            this.nestedValuesSearch = searchTerm;
            this.updatePagination('nestedValues', { page: 1, itemsPerPage: this.pagination.nestedValues.itemsPerPage });
        },
        handleStructuresSearchChange(searchTerm) {
            this.structuresSearch = searchTerm;
            this.updatePagination('structures', { page: 1, itemsPerPage: this.pagination.structures.itemsPerPage });
        },
        handleUnitsSearchChange(searchTerm) {
            this.unitSearch = searchTerm;
            this.updatePagination('units', { page: 1, itemsPerPage: this.pagination.units.itemsPerPage });
        },
        async getGroups() {
            const payload = {
                processIds: this.config.filter.processIds,
                belongsTo: "ALL"
            };

            try {
                const { data } = await ProcessService.fetchGroups(payload);

                this.groups = data.map(g => ({
                    ...g,
                    name: this.translate(g.languageKey)
                }));
            } catch (error) {
                console.error("Error fetching groups:", error);
            }
        },
        async getStructures(types) {
            const payload = {
                processIds: this.config.filter.processIds,
                groupIds: this.groupSelected.map(g => g.id),
                structureTypes: types
            };

            try {
                const { data } = await ProcessService.fetchStructures(payload);

                // Actualiza las estructuras filtradas y elimina duplicados, si es necesario
                this.structures = data.map(v => ({
                    ...v,
                    value: this.translate(v.name)
                }));

                // Limpia los valores anidados y los datos a verificar
                this.nestedValues = [];
                this.dataToCheck = [];
            } catch (error) {
                console.error("Error fetching structures:", error);
            }
        },
        async getProducts(types) {
            const payload = {
                processIds: this.config.filter.processIds,
                groupIds: this.groupSelected.map(g => g.id),
                structureIds: this.structureSelected.length > 0 ? this.structureSelected.map(s => s.id) : this.structures.map(s => s.id),
                structureTypes: types
            };

            try {
                const { data } = await ProcessService.fetchDataToSelect(payload);

                const newNestedValues = data.map(item => ({
                    ...item,
                    value: this.translate(item.name)
                }));

                this.nestedValues = [...newNestedValues];
            } catch (error) {
                console.error("Error fetching data to select:", error);
            }
        },
        async getUnits(types) {
            const payload = {
                processIds: this.config.filter.processIds,
                groupIds: this.groupSelected.map(g => g.id),
                structureIds: this.structureSelected.length > 0 ? this.structureSelected.map(s => s.id) : this.structures.map(s => s.id),
                structureTypes: types
            };

            try {
                const { data } = await ProcessService.fetchDataToSelect(payload);

                const units = data.map(item => ({
                    ...item,
                    value: this.translate(item.name)
                }));

                this.units = [...units];
            } catch (error) {
                console.error("Error fetching data to select:", error);
            }
        },
        async getNumbers(types) {
            const payload = {
                processIds: this.config.filter.processIds,
                groupIds: this.groupSelected.map(g => g.id),
                structureIds: this.structureSelected.length > 0 ? this.structureSelected.map(s => s.id) : this.structures.map(s => s.id),
                structureTypes: types
            };

            try {
                const { data } = await ProcessService.fetchDataToSelect(payload);

                const newNestedValues = data.map(item => ({
                    ...item,
                    value: this.translate(item.name)
                }));

                this.nestedValues = [...newNestedValues];
            } catch (error) {
                console.error("Error fetching data to select:", error);
            }
        },
        async getStructuresAndValues(types) {
            this.loadingFields = true;

            const promises = [];
            _.map(types, (t, key) => {
                promises.push(this.getStructures(t));
                switch (key) {
                    case 'unit':
                        promises.push(this.getUnits(t));
                        break;
                    case 'product':
                        promises.push(this.getProducts(t));
                        break;
                    case 'number':
                        promises.push(this.getNumbers(t));
                        break;
                }
            })

            await Promise.all(promises);

            this.loadingFields = false;
        },
    }
};
