<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
  >

    <template v-slot:custom-fields>
      <LoadingWrapper :loading="loadingFields">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              multiple
              :items="widgetProcess"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              v-model="config.filter.processIds"
              item-color="secondary"
              item-text="proccess_name"
              item-value="id"
              dense
              flat
              outlined
              color="secondary"
              :loading="loading.process"
              :rules="selectRule"
              @change="onSelectedProcess()"
              :menu-props="{ closeOnContentClick: false }"
            >
              <template v-slot:prepend-item>
                <v-list-item @click="() => toggleSelection('widgetProcess')">
                  <v-list-item-action>
                    <v-icon>{{ computeSelectionIcon('widgetProcess') }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{ config.filter.processIds.length === widgetProcess.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.process') }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.proccess_name | truncate(18) }}</span>
                </v-chip>
                <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                >(+{{ config.filter.processIds.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 200px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="secondary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.proccess_name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              multiple
              :items="groups"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.group')"
              v-model="groupSelected"
              item-color="secondary"
              item-text="name"
              return-object
              dense
              flat
              outlined
              color="secondary"
              :disabled="loading.process && groups.length === 0"
              :rules="selectRule"
              @change="getStructuresAndValues(customTypes)"
            >
              <template v-slot:prepend-item>
                <v-list-item @click="() => toggleSelection('groups', customTypes)">
                  <v-list-item-action>
                    <v-icon>{{ computeSelectionIcon('groups') }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{ groupSelected.length === groups.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.group") }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.name | truncate(5) }}</span>
                </v-chip>
                <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                >(+{{ groupSelected.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 200px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="secondary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              multiple
              ref="nestedValues"
              id="autocomplete"
              :items="displayedNestedValues"
              :no-data-text="$t('general.fields.noDataAvailable')"
              @update:search-input="handleNestedValuesSearchChange($event)"
              :clearable="true"
              v-model="dataToCheck"
              item-color="secondary"
              item-text="value"
              return-object
              small-chips
              dense
              flat
              outlined
              color="secondary"
              :rules="selectRule"
              :disabled="nestedValues.length === 0"
            >
              <template v-slot:prepend-item>
                <v-list-item @click="() => toggleSelection('nestedValues')">
                  <v-list-item-action>
                    <v-icon>{{ computeSelectionIcon('nestedValues') }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{ dataToCheck.length === nestedValues.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 180px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="secondary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.value"
                        :max-chars="40"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <template v-slot:append-item>
                <AFooterPaginatorField
                  :items-length="filteredNestedValues.length"
                  :items-per-page="pagination.nestedValues.itemsPerPage"
                  :itemPerPageText="false"
                  :current-page="pagination.nestedValues.currentPage"
                  :valid="true"
                  @update:current-page="(e) => updatePagination('nestedValues', { page: e.page, itemsPerPage: e.itemsPerPage })"
                  @update:items-per-page="(e) => updatePagination('nestedValues', { page: e.page, itemsPerPage: e.itemsPerPage })"
                />
              </template>
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.structure') }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.value | truncate(5) }}</span>
                </v-chip>
                <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                >(+{{ dataToCheck.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              :no-data-text="$t('general.fields.noDataAvailable')"
              @update:search-input="handleUnitsSearchChange($event)"
              item-text="value"
              return-object
              outlined
              dense
              color="secondary"
              item-color="secondary"
              v-model="config.filter.unitShowed"
              :items="displayedUnits"
              :disabled="units.length === 0"
              :rules="selectRule"
              clearable
            >
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.unitShowed') }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 180px">
                  <read-more
                    :classes="'mb-0'"
                    :text="item.value"
                    :max-chars="40"
                    :font-size="14"
                  />
                </div>
              </template>
              <template v-slot:append-item>
                <AFooterPaginatorField
                  :items-length="filteredUnits.length"
                  :items-per-page="pagination.units.itemsPerPage"
                  :itemPerPageText="false"
                  :current-page="pagination.units.currentPage"
                  :valid="true"
                  @update:current-page="(e) => updatePagination('units', { page: e.page, itemsPerPage: e.itemsPerPage })"
                  @update:items-per-page="(e) => updatePagination('units', { page: e.page, itemsPerPage: e.itemsPerPage })"
                />
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="rangeDates"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.dateRange')"
              v-model="config.filter.rangeDate"
              item-color="secondary"
              item-text="name"
              item-value="key"
              dense
              flat
              outlined
              color="secondary"
              :rules="selectRule"
            >
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.dateRange') }}
                <strong class="red--text">*</strong>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </LoadingWrapper>
    </template>
  </FormComponent>
</template>

<script>
  import FormComponent from "@/module/dashboard/components/common/FormComponent.vue";
  import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue";
  import IconPicker from "@/module/dashboard/components/common/IconPicker.vue";
  import {GlobalWidgetMixin} from "@/module/dashboard/mixins/global-widget.mixin";
  import {CommonWidgetMixin} from "@/module/dashboard/mixins/common-widget.mixin";
  import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
  import i18n from "@/plugins/i18n";
  import LoadingWrapper from "@/module/dashboard/components/common/LoadingWrapper.vue"
  import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue"
  import ReadMore from "@/components/ReadMoreComponent.vue"

  export default {
    name: 'ProductsByLocationsDialog',
    components: { ReadMore, AFooterPaginatorField, LoadingWrapper, FormComponent, IconPicker, ColorPicker},
    mixins: [GlobalWidgetMixin, CommonWidgetMixin, FormWidgetMixin],
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({
          id: null,
          title: i18n.t('widgets.names.QUANTITY_PRODUCTS_BY_STATE_LOCATION'),
          icon: 'mdi-chart-box-outline',
          color: '#232323',
          filter: {
            processIds: [],
            groupIds: [],
            nestedValues: [],
            unitShowed: null,
            rangeDate: null
          },
          format: {
            region: null,
            afterPoint: null
          },
          subtype: 'QUANTITY_PRODUCTS_BY_STATE_LOCATION',
          type: 'CHART'
        })
      }
    },
    data() {
      return {
        dialog: false,
        valid: true,
        config: {
          id: null,
          title: i18n.t('widgets.names.QUANTITY_PRODUCTS_BY_STATE_LOCATION'),
          icon: 'mdi-chart-box-outline',
          color: '#232323',
          filter: {
            processIds: [],
            groupIds: [],
            nestedValues: [],
            unitShowed: null,
            rangeDate: null
          },
          format: {
            region: null,
            afterPoint: null
          },
          subtype: 'QUANTITY_PRODUCTS_BY_STATE_LOCATION',
          type: 'CHART'
        },
        rangeDates: [
          {
            key: 'DAY',
            name: i18n.t('widgets.dialogs.rangeDate.day')
          },{
            key: 'WEEK',
            name: i18n.t('widgets.dialogs.rangeDate.week')
          },{
            key: 'MONTH',
            name: i18n.t('widgets.dialogs.rangeDate.month')
          },{
            key: 'YEAR',
            name: i18n.t('widgets.dialogs.rangeDate.year')
          }
        ],
        defaultConfig: null,
        loadingFields: false,
        customTypes: { product: ['PRODUCT', 'PRODUCTSIMPLE'], unit: ['MEASUREMENTUNIT', 'MEASUREMENTUNITSIMPLE'] }
      };
    },
    watch: {
      valid() {
        this.$emit('update:validation', this.valid);
      }
    },
    created() {
      this.defaultConfig = { ...this.config }
      this.config = {
        ...this.item.props,
        format: this.item.props.format || {
          region: null,
          afterPoint: null
        },
        subtype: "QUANTITY_PRODUCTS_BY_STATE_LOCATION",
        icon: "mdi-chart-box-outline",
        info: "QUANTITY_PRODUCTS_BY_STATE_LOCATION",
      };
    },
    methods: {
      saveConfig(data) {
        const config = {
          ...data,
          filter: {
            processIds: [...this.config.filter.processIds],
            groupIds: this.groupSelected.map(g => g.id),
            nestedValues: this.dataToCheck.map(d => d.value),
            unitShowed: this.config.filter.unitShowed && this.config.filter.unitShowed.id ? this.config.filter.unitShowed.id : this.config.filter.unitShowed || null,
            rangeDate: this.config.filter.rangeDate.key || this.config.filter.rangeDate
          },
          initialized: true
        }

        this.$emit('update:validation', this.valid);
        this.$emit('update:config', config);
        this.dialog = false;
      },
      async handleInitData() {
        if (this.item.initialized) {
          this.loadingFields = true;

          await this.getGroups();
          this.groupSelected = this.groups.filter(g => this.config.filter.groupIds.includes(g.id));

          await this.$nextTick();

          await this.getStructuresAndValues(this.customTypes);

          await this.$nextTick();

          this.dataToCheck = this.nestedValues.filter(nv => this.config.filter.nestedValues.includes(nv.value));
          this.config.filter.unitShowed = _.find(this.units, { id: this.config.filter.unitShowed ? this.config.filter.unitShowed.id || this.config.filter.unitShowed : null })
          this.config.filter.rangeDate = _.find(this.rangeDates, { key: this.config.filter.rangeDate.key || this.config.filter.rangeDate })

          this.loadingFields = false;
        } else {
          this.config = { ...this.defaultConfig };
        }
      },
      clear(e) {
        this.config = { ...e }
        this.saveConfig(e)
      }
    }
  };
</script>

<style scoped>
</style>
