<template>
  <v-row class="d-flex">
    <v-col cols="12">
      <v-menu
        v-model="menu"
        ref="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="formattedDate"
            :label="label"
            append-icon="mdi-calendar"
            readonly
            v-on="on"
            :clearable="true"
            dense
            flat
            outlined
            color="secondary"
            hide-details
            @click:clear="clearDates"
            @click:append="toggleMenu"
          ></v-text-field>
        </template>
        <v-date-picker
          color="secondary"
          v-model="dates"
          range
          :min="minDate"
          @input="updateDates"
          :locale="$i18n.locale"
          :selected-items-text="
           '{0} ' + $t('records.filters.selected_items_text')
          "
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="gray"
            @click="clearDates"
          >
            {{ $t('general.buttons.clean') }}
          </v-btn>
          <v-btn
            :disabled="dates.length < 2"
            text
            color="secondary"
            @click="confirmDates"
          >
            {{ $t('general.buttons.confirm') }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DateRangePicker",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String
    },
  },
  data() {
    return {
      menu: false,
      dates: this.value,
      minDate: null,
    };
  },
  computed: {
    formattedDate: {
      get() {
        if (this.dates.length === 2) {
          // Usar "~" como delimitador y espacios adecuadamente
          return `${this.formatDate(this.dates[0])} ~ ${this.formatDate(this.dates[1])}`;
        }
        return "";
      },
      set(newValue) {
        if (!newValue) {
          this.dates = [];
          return;
        }
        // Dividir la cadena sin espacios adicionales si el delimitador es "-"
        const datesArray = newValue.split(" - ").map(date => {
          const parts = date.split("/");
          return `${parts[2]}-${parts[1]}-${parts[0]}`;
        });

        if (this.isValidDate(datesArray[0]) && this.isValidDate(datesArray[1])) {
          this.dates = datesArray;
        }
      },
    },
  },
  methods: {
    toggleMenu() {
      this.menu = !this.menu;
    },
    clearDates() {
      this.dates = [];
      this.minDate = null;
      this.menu = false;
      this.$emit('update:dates', this.dates);
    },
    updateDates(val) {
      this.dates = val;
      if (val.length === 1) {
        this.minDate = val[0];
      } else {
        this.minDate = null;
      }
    },
    confirmDates() {
      this.$emit('update:dates', this.dates);
      this.menu = false;
    },
    formatDate(date) {
      const [year, month, day] = date.split("-");
      return `${day} / ${month} / ${year}`;
    },
    isValidDate(dateString) {
      // Verificar si la cadena representa una fecha válida
      const regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateString.match(regEx)) return false;  // Formato inválido
      const d = new Date(dateString);
      const dNum = d.getTime();
      if (!dNum && dNum !== 0) return false; // NaN value, fecha inválida
      return d.toISOString().slice(0,10) === dateString;
    }
  },
  watch: {
    value(val) {
      this.dates = val;
    },
  },
};
</script>
