<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
  >

    <template v-slot:custom-fields>
      <v-container class="ma-0 pa-0">
        <v-row>
          <v-col cols="12" class="py-0 pb-4">
            <v-select
              :items="rangeDates"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.dateRange')"
              v-model="config.filter.rangeDate"
              item-color="secondary"
              item-text="name"
              item-value="key"
              dense
              flat
              outlined
              color="secondary"
              :rules="selectRule"
            >
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.dateRange') }}
                <strong class="red--text">*</strong>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from "@dashboard/components/common/FormComponent.vue";
import ColorPicker from "@dashboard/components/common/ColorPicker.vue";
import IconPicker from "@dashboard/components/common/IconPicker.vue";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
import { GlobalWidgetMixin } from "@/module/dashboard/mixins/global-widget.mixin";
import i18n from "@/plugins/i18n";

export default {
  name: 'QrLastVisitDialog',
  components: {FormComponent, IconPicker, ColorPicker},
  mixins: [GlobalWidgetMixin, FormWidgetMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.LAST_QR_VISIT_BY_RANGE'),
        icon: 'mdi-chart-timeline-variant',
        color: '#232323',
        filter: {
          rangeDate: null
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'LAST_QR_VISIT_BY_RANGE',
        type: 'CHART'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.LAST_QR_VISIT_BY_RANGE'),
        icon: 'mdi-chart-timeline-variant',
        color: '#232323',
        filter: {
          rangeDate: null
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'LAST_QR_VISIT_BY_RANGE',
        type: 'CHART'
      },
      rangeDates: [
        {
          key: 'DAY',
          name: i18n.t('widgets.dialogs.rangeDate.day')
        },{
          key: 'WEEK',
          name: i18n.t('widgets.dialogs.rangeDate.week')
        },{
          key: 'MONTH',
          name: i18n.t('widgets.dialogs.rangeDate.month')
        },{
          key: 'YEAR',
          name: i18n.t('widgets.dialogs.rangeDate.year')
        }
      ],
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "LAST_QR_VISIT_BY_RANGE",
      icon: "mdi-chart-timeline-variant",
      info: "LAST_QR_VISIT_BY_RANGE",
    };
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          rangeDate: this.config.filter.rangeDate.key || this.config.filter.rangeDate
        },
        initialized: true
      }

      this.$emit('update:validation', this.valid);
      this.$emit('update:config', config);
      this.dialog = false;
    },
    async handleInitData() {
      if (this.item.initialized) {
        this.config.filter.rangeDate = _.find(this.rangeDates, { key: this.config.filter.rangeDate.key || this.config.filter.rangeDate })
      }
    },
    clear(e) {
      this.config = {...e}
      this.saveConfig(e)
    }
  }
};
</script>

<style scoped>
</style>
