<template>
  <div @dragend="dragend" @drag="drag" class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-card" :style="borderColor">

      <ChipWidgetSubtype :name="$t('widgets.names.IMAGE')" icon="mdi-image" :info="$t('widgets.descriptions.IMAGE')" />

      <p class="ma-0 pa-0 font-weight-bold" :style="`color: ${data.color}80`"></p>
      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div class="skeleton-image d-flex justify-center align-center">
        <v-icon size="80">mdi-image</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";
import i18n from "@/plugins/i18n";

export default {
  name: 'ImageSkeleton',
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      data: {
        type: 'CARD',
        subtype: 'IMAGE',
        props: {
          title: i18n.t('widgets.names.IMAGE'),
          values: [],
          icon: 'mdi-image',
          color: '#232323',
          filter: {
            url: null
          }
        },
        w: 5,
        h: 5,
        initialized: false,
        valid: false
      }
    };
  },
  computed: {
    borderColor() {
      return {
        borderLeft: `4px solid ${this.data.color}60`
      };
    },
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },
    valueColor() {
      return {
        color: this.data.color
      };
    }
  },
  methods: {
    drag(e) {
      // Emit an event with the information necessary to create the new item
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    }
  }
};
</script>

<style scoped>
@import "../../../../styles/scss/components/_skeleton.scss";
@import "../../../../styles/scss/components/skeletons/_image.scss";
</style>
