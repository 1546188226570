import iconsData from '@dashboard/components/common/MdiIconsMeta.json';

export const getPackages = async () => {
    try {
        const tags = new Set();
        iconsData.forEach(icon => {
            icon.tags.forEach(tag => tags.add(tag));
        });
        // Convertir el Set de tags a un array de objetos simulando paquetes
        return Array.from(tags).map(tag => ({ id: tag, name: tag }));
    } catch (error) {
        console.error('Error al obtener los paquetes', error);
        return [];
    }
};

export const getIconsByPackageId = async (packageName) => {
    console.log(packageName);
    try {
        return iconsData.filter(icon => !packageName || icon.tags.includes(packageName));
    } catch (error) {
        console.error(`Error al obtener iconos del paquete ${packageName}`, error);
        return [];
    }
};


