<template>
  <div @dragend="dragend" @drag="drag" class="skeleton-wrapper" draggable="true" unselectable="on">
    <div class="skeleton-chart">

      <ChipWidgetSubtype :name="$t('widgets.names.LAST_QR_VISIT_BY_RANGE')" icon="mdi-chart-timeline-variant" :info="$t('widgets.descriptions.LAST_QR_VISIT_BY_RANGE')" />

      <div class="d-flex justify-space-between align-center">
        <div class="skeleton-icon" :style="iconColor"></div>
        <div class="skeleton-title"></div>
      </div>
      <div class="skeleton-wrapper">
        <div class="skeleton-chart pa-2">
          <svg width="100%" height="100%" viewBox="0 -10 200 110">
            <!-- Definición del gradiente -->
            <defs>
              <linearGradient id="lineGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="5%" stop-color="#cccccc" stop-opacity="0.5" /> <!-- Color más oscuro -->
                <stop offset="85%" stop-color="#cccccc" stop-opacity="0" />
              </linearGradient>
            </defs>
            <!-- Path para el relleno con gradiente -->
            <path :d="fillPathD" fill="url(#lineGradient)" />
            <!-- Path para la línea visible -->
            <path :d="linePathD" fill="none" stroke="#e0e0e0" stroke-width="4"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipWidgetSubtype from "@/module/dashboard/components/common/ChipWidgetSubtype.vue";
import i18n from "@/plugins/i18n";


export default {
  components: {ChipWidgetSubtype},
  data() {
    return {
      color: '#232323',
      linePathD: '',
      fillPathD: '',
      data: {
        type: 'CHART',
        subtype: 'LAST_QR_VISIT_BY_RANGE',
        props: {
          title: i18n.t('widgets.names.LAST_QR_VISIT_BY_RANGE'),
          filter: {
            rangeDate: null
          },
          values: [{
            xAxis: ['L','M','M','J','V','S','D'],
            yAxis: [10,100,234,23,98,34,122]
          }],
          color: '#232323',
          icon: 'mdi-chart-timeline-variant',
        },
        w: 6,
        h: 12,
        initialized: false,
        valid: false
      }
    };
  },
  mounted() {
    this.generatePath();
  },
  computed: {
    iconColor() {
      return {
        backgroundColor: `${this.color}80`
      };
    },
  },
  methods: {

    generatePath() {
      const points = this.generatePoints();
      this.linePathD = this.generateLinePathD(points);
      this.fillPathD = this.generateFillPathD(points);
    },
    generateLinePathD(points) {
      // Genera el atributo 'd' para la línea visible del path
      let d = `M ${points[0].x} ${points[0].y}`;
      for (let i = 1; i < points.length; i++) {
        const cp = this.controlPoint(points[i - 1], points[i]);
        d += ` S ${cp.x} ${cp.y} ${points[i].x} ${points[i].y}`;
      }
      return d;
    },
    generateFillPathD(points) {
      // Genera el atributo 'd' para el relleno del path incluyendo el cierre
      let d = this.generateLinePathD(points); // Comienza con la línea visible
      // Continúa el path hacia abajo y cierra el path sin añadir stroke
      d += ` L ${points[points.length - 1].x} 100 L ${points[0].x} 100 Z`;
      return d;
    },
    generatePoints() {
      // Genera puntos aleatorios para el gráfico
      const points = [];
      for (let i = 0; i < 20; i++) {
        const x = i * 20; // Asegúrate de que la x se distribuya uniformemente
        const y = Math.random() * 80; // La y es un valor aleatorio
        points.push({ x, y });
      }
      return points;
    },
    controlPoint(pointA, pointB) {
      // Calcula el punto de control para la curva de Bézier
      return {
        x: (pointA.x + pointB.x) / 2,
        y: (pointA.y + pointB.y) / 2
      };
    },
    drag(e) {
      // Emite un evento global con la información necesaria para crear el nuevo ítem
      this.$root.$emit('drag-item', this.data);
    },
    dragend(e) {
      this.$root.$emit('dragend-item', this.data);
    }
  }
}
</script>

<style scoped>
@import "../../../../../styles/scss/components/_skeleton.scss";
@import "../../../../../styles/scss/components/skeletons/_chart.scss";
</style>
