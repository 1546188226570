<template>
  <CardWrapper
    :cardConfig="cardConfig"
    :isValid="isValid"
    :isEditingEnabled="isEditingEnabled"
    :loading="loading"
    :item="item"
    @reload:requested="reloadData"
  >
    <template #values>
      <RenderValues :applicable-filters="item.applicableFilters" ref="renderValues" :widget-id="item.props.id" :t-color="cardConfig.color" :initialized="item.initialized" :with-unit="true" />
    </template>
    <template #dialog>
      <SumDialog @update:validation="checkValidation($event)" :item="item" @update:config="updateConfig($event)" />
    </template>
  </CardWrapper>
</template>

<script>
import SumDialog from "@/module/dashboard/components/widgets/card/sum/SumDialog.vue";
import CardWrapper from "@/module/dashboard/components/widgets/card/common/CardWrapper.vue"
import RenderValues from "@/module/dashboard/components/widgets/card/common/RenderValues.vue";
import { CardWidgetMixin } from "@/module/dashboard/mixins/card-widget.mixin";
import { ReloadWidgetMixin } from "@/module/dashboard/mixins/reload-widget.mixin";
import ShowFilteredData from "@/module/dashboard/components/common/ShowFilteredData.vue";
export default {
  name: "SumCard",
  components: {ShowFilteredData, CardWrapper, RenderValues, SumDialog },
  mixins: [CardWidgetMixin, ReloadWidgetMixin],
};
</script>
