import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormComponent',{attrs:{"dialog":_vm.dialog,"config":_vm.config,"valid":!!(_vm.valid && _vm.isUrlValid),"extra-valid":_vm.isUrlValid,"advanced-options":{format: false, itemsLimit: false}},on:{"save:form":_vm.saveConfig,"update:dialog":function($event){_vm.dialog = $event},"update:valid":function($event){_vm.valid = ($event && _vm.isUrlValid)},"load:init-data":_vm.handleInitData,"clear:form":function($event){return _vm.clear($event)}},scopedSlots:_vm._u([{key:"custom-fields",fn:function(){return [(!_vm.config.filter.url)?_c('div',[_c(VFileInput,{attrs:{"label":_vm.$t('widgets.dialogs.fields.uploadFile'),"color":"secondary","outlined":"","dense":"","rules":_vm.requireRules,"prepend-icon":""},on:{"click:clear":_vm.clearData,"change":_vm.fileSelected},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VBtn,{staticClass:"cursor-pointer",attrs:{"disabled":!_vm.config.filter.file,"icon":"","loading":_vm.loading.upload},on:{"click":function($event){return _vm.uploadS3File()}}},[_c(VIcon,[_vm._v("mdi-upload")])],1)]},proxy:true}],null,false,594899850),model:{value:(_vm.config.filter.file),callback:function ($$v) {_vm.$set(_vm.config.filter, "file", $$v)},expression:"config.filter.file"}}),(_vm.config.filter.file && !_vm.config.filter.url)?_c(VAlert,{attrs:{"type":"warning","dense":"","text":"","border":"left"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.uploadMessage)}})]):_vm._e()],1):_c('div',{staticClass:"image-preview my-4"},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VImg,{attrs:{"src":_vm.config.filter.url,"contain":"","max-height":"300px","max-width":"300px"}},[_c(VFadeTransition,[(hover)?_c(VOverlay,{attrs:{"absolute":"","color":"white"}},[_c(VBtn,{attrs:{"fab":""},on:{"click":_vm.clearData}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close-circle")])],1)],1):_vm._e()],1)],1)]}}])})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }