import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c(VRow,{staticClass:"ma-0 pa-0 d-flex justify-space-between"},[_c(VCol,{staticClass:"ma-0 pa-2",attrs:{"cols":"4","align-self":"start","justify":"start"}},[(!_vm.isEditingEnabled)?_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('widgets.search'),"dense":"","outlined":"","background-color":"#EAEAEA80","color":"secondary","clearable":false,"hide-details":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fetchData()},"click:clear":function($event){return _vm.fetchData()},"click:append":function($event){return _vm.fetchData()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1),_c(VCard,{staticClass:"ma-0 pa-0 fill-height overflow-hidden d-flex justify-space-between full-width",attrs:{"elevation":"0"}},[_c('LoadingWidgetData',{attrs:{"loading":_vm.loading}}),_c('EmptyWidgetData',{attrs:{"is-empty":!_vm.isPresent && !_vm.loading}}),_c(VDataTable,_vm._b({staticClass:"elevation-0 overflow-y-auto full-width",attrs:{"headers":_vm.headers,"items":_vm.values,"items-per-page":5,"loading":_vm.loading,"no-results-text":_vm.$t('general.ui.not_search_found'),"no-data-text":_vm.$t('general.ui.not_data_found'),"loading-text":_vm.$t('general.notification.loadData'),"footer-props":{
          itemsPerPageText: _vm.$t('general.table.itemsPerPageText'),
          pageText: '{0}-{1} ' + _vm.$t('general.table.pageText') + ' {2}',
        }}},'v-data-table',_vm.tableProps,false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }