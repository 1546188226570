import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAutocomplete,{attrs:{"multiple":"","items":_vm.stages,"no-data-text":_vm.$t('general.fields.noDataAvailable'),"clearable":true,"item-color":"secondary","item-text":"storage_type_name","item-value":"storage_device_type_id","dense":"","flat":"","outlined":"","color":"secondary","menu-props":{ closeOnContentClick: false }},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,{on:{"click":() => _vm.toggleSelection()}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(_vm.computeSelectionIcon()))])],1),_c(VListItemContent,[_vm._v(" "+_vm._s(_vm.filters.stages.length === _vm.stages.length ? _vm.$t('general.titles.deselectAll') : _vm.$t('general.titles.selectAll'))+" ")])],1)]},proxy:true},{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('widgets.filters.stages'))+" ")]},proxy:true},{key:"selection",fn:function({ item, index }){return [(index === 0)?_c(VChip,{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm._f("truncate")(item.storage_type_name,18)))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption",staticStyle:{"font-size":"10px !important"}},[_vm._v("(+"+_vm._s(_vm.filters.stages.length - 1)+" "+_vm._s(_vm.$t('general.titles.others'))+")")]):_vm._e()]}},{key:"item",fn:function({ item, attrs }){return [_c(VCheckbox,{staticClass:"pt-2 pb-2 mt-0",attrs:{"input-value":attrs.inputValue,"color":"secondary","hide-details":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('read-more',{attrs:{"classes":'mb-0',"text":item.storage_type_name,"max-chars":160,"font-size":14}})]},proxy:true}],null,true)})]}}]),model:{value:(_vm.filters.stages),callback:function ($$v) {_vm.$set(_vm.filters, "stages", $$v)},expression:"filters.stages"}})
}
var staticRenderFns = []

export { render, staticRenderFns }