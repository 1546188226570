<template>
  <v-autocomplete
    :multiple="false"
    :items="locationTypes"
    :no-data-text="$t('general.fields.noDataAvailable')"
    :clearable="true"
    v-model="filters.locationType"
    item-color="secondary"
    item-text="name"
    item-value="key"
    dense
    flat
    outlined
    color="secondary"
    @change="filters.classificationTypes = []"
    :menu-props="{ closeOnContentClick: false }"
  >
    <template v-slot:label>
      {{ $t('widgets.dialogs.filters.locationType') }}
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip  small>
        <span>{{ item.name | truncate(18) }}</span>
      </v-chip>
    </template>
    <template v-slot:item="{ item, attrs }">
      <read-more
        :classes="'mb-0'"
        :text="item.name"
        :max-chars="160"
        :font-size="14"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import ReadMore from "@/components/ReadMoreComponent.vue";
import i18n from "@/plugins/i18n"

export default {
  name: "LocationTypePicker",
  components: { ReadMore },
  data: () => ({
    locationTypes: [
      {
        key: 'PRIMARY',
        name: i18n.t('locations.fields.name')
      },
      {
        key: 'SECONDARY',
        name: i18n.t('storage.fields.name')
      }
    ],
  }),
  computed: {
    filters: {
      get() {
        return this.$store.getters['dashboard/GET_FILTERS'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_FILTERS', val);
      }
    },
  },
  methods: { }
}
</script>

<style scoped>

</style>