
export const ReloadWidgetMixin = {
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async reloadData() {
            this.loading = true;
            await this.$refs.renderValues.fetchData().finally(() => {
                this.loading = false;
            });
        },
    },
};
