import { render, staticRenderFns } from "./ImageRender.vue?vue&type=template&id=5d52bf36&scoped=true&"
import script from "./ImageRender.vue?vue&type=script&lang=js&"
export * from "./ImageRender.vue?vue&type=script&lang=js&"
import style0 from "./ImageRender.vue?vue&type=style&index=0&id=5d52bf36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d52bf36",
  null
  
)

export default component.exports